import React, { Component } from "react"

import { Transaction, Cards, Process, Avatar } from '../../components'

/* Modal */
import Modal from 'react-modal'

/* REST API */
import { view, credit } from '../../api/EMP'

/* Widgets */
import { Layout } from '../Table'

/* Constants */
import { utils } from "../../helpers"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Sweet alert */
import Swal from "sweetalert2"


/* Widget Player */
class EMPPlayer extends Component {

    constructor() {
        super()

        this.state = {
            data: null,
            status: "loading",

            visible: false,
            type: "",
            amount: "",
            waiting: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load action */
    load = (reload = false) => {

        const { uid, gameID, active } = this.props
        console.log("UUID" + uid, gameID, active)

        if (!uid || !gameID || !active) {
            this.setState({ status: 'error', data: null })
            return
        }

        if (reload) {
            this.setState({ status: "loading" })
        }

        view({ uid, gameID, player: active.player }).then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: null })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: null })
            }
            else {
                this.setState({ status: "error", data: null })
            }
        }).catch(() => {
            this.setState({ status: "network", data: null, table: null, total: [], cards: [] })
        })

    }


    /* Get Cards */
    getCards = type => {

        /* Fields */
        const { data } = this.state
        let list = []

        if (data.cards && Array.isArray(data.cards) && data.cards.length > 0) {
            data.cards.forEach(card => {
                if (card.type === type) {
                    list.push(card)
                }
            })
        }

        return list
    }


    /* Draw Payment Modal */
    _modal = () => {

        /* Fields */
        const { visible, type, amount, data, waiting } = this.state

        return (
            <Modal
                isOpen={visible}
                onRequestClose={() => this.setState({ visible: false, type: "", amount: "" })}
                className={`refund--modal`}
                overlayClassName="refund--overlay"
                closeTimeoutMS={200}
            >
                <div className="refund--modal-header">
                    <h2>{type === "refund" ? "Возврат ставки" : "Оплата суммы"}</h2>
                    <div onClick={() => this.setState({ visible: false, type: "", amount: "" })} className="refund--modal-close">
                        <img src="/images/close.png" alt="Close" />
                    </div>
                </div>

                <div className="refund--modal-content">

                    {type === "refund" &&
                        <div className="refund--modal-information">
                            <div className="refund--modal-information-item">
                                <div className="refund--modal-information-text">Общая ставка</div>
                                <div className="refund--modal-information-name">{data.game.total ? utils.convertor(data.game.total, data.game.symbol, data.game.isAfter) : "-"}</div>
                            </div>
                            <div className="refund--modal-information-item">
                                <div className="refund--modal-information-text">Возвращено</div>
                                <div className="refund--modal-information-name">{data.game.refund ? utils.convertor(data.game.refund, data.game.symbol, data.game.isAfter) : "-"}</div>
                            </div>
                        </div>
                    }

                    {type === "payment" &&
                        <div className="refund--modal-information">
                            <div className="refund--modal-information-item">
                                <div className="refund--modal-information-text">ANTE</div>
                                <div className="refund--modal-information-name">{data.game.ante ? utils.convertor(data.game.ante, data.game.symbol, data.game.isAfter) : "-"}</div>
                            </div>
                            <div className="refund--modal-information-item">
                                <div className="refund--modal-information-text">Оплачено</div>
                                <div className="refund--modal-information-name">{data.game.paid ? utils.convertor(data.game.paid, data.game.symbol, data.game.isAfter) : "-"}</div>
                            </div>
                        </div>
                    }

                    <input placeholder="Введите сумму" type="text" value={amount} onChange={event => this.setState({ amount: event.target.value.replace(/\D/, '') })} />

                    {waiting
                        ? <div className="refund--modal-button disabled"><LoadingOutlined /></div>
                        : <div className="refund--modal-button" onClick={() => this.send(type)}>Оплатить</div>
                    }

                </div>

            </Modal>
        )
    }

    /* Refund & Pay actions */
    send = type => {

        const { amount, data } = this.state
        const { uid, socket } = this.props

        if (amount === null || amount === undefined || amount === "" || parseFloat(amount) === 0) {
            Swal.fire(utils.notification("Введите сумму", "error"))
            return
        }

        this.setState({ waiting: true })

        /* SEND TO REST API */
        credit({ uid, type, refund: amount, playerID: data.game.player, gameID: data.game.gameID }).then(response => {
            if (response.status === 200) {

                if (type === "refund") {
                    socket.emit("adminNotification", { type: "refund", title: "Возврат средств: ", total: amount, currency: data.currency })
                }
                else {
                    socket.emit("adminNotification", { type: "payment", title: "Пополнение суммы: ", total: amount, currency: data.currency })
                }

                Swal.fire(utils.notification(type === "refund" ? "Возврат успешно завершен" : "Оплата успешно завершена", "success"))

            }
            else {
                Swal.fire(utils.notification("Что-то пошло не так! Попробуйте позднее!", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-то пошло не так! Попробуйте позднее!", "error"))
        }).finally(() => {
            this.load()
            this.setState({ waiting: false, type: "", visible: false, amount: "" })
        })

    }


    /* Draw Content */
    _content = () => {

        const { status, data } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        let total = 0

        if (data.game.debit) {
            total = total + parseInt(data.game.debit)
        }
        if (data.game.credit) {
            total = total - parseInt(data.game.credit)
        }

        return (
            <div className="player-game--modal-information">

                <div className="emp--game-information-row">

                    {/* Player Information */}
                    <div className='emp--game-information-element'>

                        {/* Player */}
                        <div className="emp--game-information-item">
                            <Avatar uri="/images/player/gamer.png" size={24} />
                            <div className="emp--game-information-item-box">
                                <div className="emp--game-information-item-title">Игрок {data.game.player}</div>
                                <div className="emp--game-information-item-text">{data.game.firstName}</div>
                            </div>
                        </div>

                        {/* Start Balance */}
                        <div className="evp--game-information-item">
                            <img src="/images/game/dollar-coin.png" alt="Dollar" />
                            <div className="evp--game-information-item-box">
                                <div className="evp--game-information-item-title">Баланс</div>
                                <div className="evp--game-information-item-text">{data.game.startBalance ? utils.convertor(data.game.startBalance, data.game.symbol, data.game.isAfter) : "-"}</div>
                            </div>
                        </div>

                        {/* ANTE */}
                        <div className="evp--game-information-item">
                            <img src="/images/game/dollar-coin.png" alt="Dollar" />
                            <div className="evp--game-information-item-box">
                                <div className="evp--game-information-item-title">ANTE</div>
                                <div className="evp--game-information-item-text">{data.game.ante ? utils.convertor(data.game.ante, data.game.symbol, data.game.isAfter) : "-"}</div>
                            </div>
                        </div>

                        {/* TOTAL */}
                        <div className="evp--game-information-item">
                            <img src="/images/game/dollar-coin.png" alt="Dollar" />
                            <div className="evp--game-information-item-box">
                                <div className="evp--game-information-item-title">Общая ставка</div>
                                <div className="evp--game-information-item-text">{data.game.total ? utils.convertor(data.game.total, data.game.symbol, data.game.isAfter) : "-"}</div>
                            </div>
                        </div>

                    </div>

                    {/* Player Information */}
                    <div className='emp--game-information-element'>

                        {/* Start Balance */}
                        <div className="evp--game-information-item">
                            <img src="/images/game/increase.png" alt="Increase" />
                            <div className="evp--game-information-item-box">
                                <div className="evp--game-information-item-title">DEBIT</div>
                                <div className="evp--game-information-item-text">{data.game.debit ? utils.convertor(data.game.debit, data.game.symbol, data.game.isAfter) : "-"}</div>
                            </div>
                        </div>

                        {/* ANTE */}
                        <div className="evp--game-information-item">
                            <img src="/images/game/decrease.png" alt="Decrease" />
                            <div className="evp--game-information-item-box">
                                <div className="evp--game-information-item-title">CREDIT</div>
                                <div className="evp--game-information-item-text">{data.game.credit ? utils.convertor(data.game.credit, data.game.symbol, data.game.isAfter) : "-"}</div>
                            </div>
                        </div>

                        {/* TOTAL */}
                        <div className="evp--game-information-item">
                            <img src="/images/game/calculate.png" alt="Calculate" />
                            <div className="evp--game-information-item-box">
                                <div className="evp--game-information-item-title">Итого</div>
                                <div className={`emp--game-information-item-text ${total >= 0 ? "green" : 'red'}`}>{total >= 0 ? "+ " : "- "} {utils.convertor(Math.abs(total), data.game.symbol, data.game.isAfter)}</div>
                            </div>
                        </div>

                    </div>


                    <div className="evp--game-information-big-element">
                        <div className="evp--game-information-text">Payment actions</div>

                        <div className="evp--game-buttons">
                            <div onClick={() => this.setState({ visible: true, type: "refund" })} className="evp--game-button purple">Возврат ставки</div>
                            <div onClick={() => this.setState({ visible: true, type: "payment" })} className="evp--game-button pink">Оплата суммы</div>
                        </div>
                    </div>


                </div>

                {/* Processes */}
                <Process game="multi" count={1} symbol={data.game.symbol} data={data.processes} />

                {/* Transactions */}
                <Transaction symbol={data.game.symbol} data={data.transactions} />

                {/* Cards */}
                <div className="cards--container">
                    <Cards title="Dealer Cards" cards={this.getCards("dealer")} />
                    <Cards title="Player Cards" cards={this.getCards("player")} />
                </div>

            </div>
        )
    }

    render = () => {

        const { visible, close } = this.props

        return (
            <Modal
                isOpen={visible}
                onRequestClose={() => close()}
                className={`player-game--modal`}
                overlayClassName="player-game--overlay"
                closeTimeoutMS={200}
            >

                <div className="player-game--modal-header">
                    <h2>Информация о игроке</h2>
                    <div onClick={() => close()} className="player-game--modal-close">
                        <img src="/images/close.png" alt="Close" />
                    </div>
                </div>

                {this._content()}

                {this._modal()}

            </Modal>
        )
    }

}

export default EMPPlayer