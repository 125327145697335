import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Helpers */
import { utils, withRouter } from '../../helpers'

/* Swal */
import Swal from 'sweetalert2'

/* Components */
import { Input, Layout } from "../../components"

/* REST API */
import { edit, get } from '../../api/Dealer'



/* Page Dealer Edit */
class DealerEdit extends Component {


    constructor() {
        super()

        this.state = {
            name: "",
            pincode: "",
            waiting: false,
            status: "loading"
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load action */
    load = (reload = false) => {

        const { params } = this.props
        const { id } = params

        if (id) {

            if (reload) {
                this.setState({ status: "loading" })
            }

            get({ id }).then(response => {
                if (response.status === 200) {
                    const data = response.data
                    this.setState({
                        name: utils.checkValue(data.name),
                        pincode: utils.checkValue(data.pincode),
                        status: "ok"
                    })
                }
                else if (response.status === 401 || response.status === 403) {
                    this.setState({ status: "permission" })
                }
                else if (response.status === 498) {
                    this.setState({ status: "key" })
                }
                else {
                    this.setState({ status: "error" })
                }
            }).catch(() => {
                this.setState({ status: "network" })
            })
        }
        else {
            this.setState({ status: "error" })
        }

    }



    /* Save action */
    save = () => {

        const { name, pincode } = this.state
        const { navigate, params } = this.props
        const { id } = params

        if (!id) {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            return
        }

        if (name === "") {
            this._name.error("Введите имя дилера")
            return
        }

        if (pincode === "") {
            this._pincode.error("Введите PIN/Barcode")
            return
        }

        if (pincode.length !== 6) {
            this._pincode.error("Длина PIN/штрих-кода должна быть 6 символов")
            return
        }

        this.setState({ waiting: true })

        edit({ id, name, pincode }).then(response => {
            if (response.status === 200) {
                Swal.fire(utils.notification("Дилер успешно сохранен", "success")).then(() => {
                    navigate(-1)
                })
            }
            else if (response.status === 401 || response.status === 403) {
                Swal.fire(utils.notification("Нет доступа!", "error"))
            }
            else if (response.status === 498) {
                Swal.fire(utils.notification("Неверный ключ!", "error"))
            }
            else {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
        }).finally(() => {
            this.setState({ waiting: false })
        })

    }

    /* Draw button */
    _button = () => {

        const { waiting } = this.state

        if (waiting) {
            return <div className="button"><LoadingOutlined /></div>
        }

        return <div onClick={() => this.save()} className="button">Сохранить</div>
    }

    render = () => {

        const { name, pincode, status } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Изменить данные</h1>
                </div>

                <div className="page-form">
                    <div className="form">
                        <div className="form-box">
                            <Input ref={ref => this._name = ref} label="Имя дилера" value={name} onChange={event => this.setState({ name: event.target.value })} />
                            <Input ref={ref => this._pincode = ref} label="PIN/Barcode" value={pincode} onChange={event => this.setState({ pincode: event.target.value })} />
                        </div>
                    </div>

                    <div className="form-bottom">
                        {this._button()}
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(DealerEdit)