import React, { Component } from "react"

/* Sweet alert */
import Swal from "sweetalert2"

/* REST API */
import { refresh } from '../../api/Stream'



/* Fields */
const DATA = [
    {
        name: "Stream PP",
        stream: "https://mac-str-pp.makaobet.com/pp/ngrp:str_all/playlist.m3u8",
        url: "http://10.20.30.30:8087/v2/servers/_defaultServer_/vhosts/_defaultVHost_/applications/pp/actions/restart",
        method: "put",
        user: "poker-rest-api-caller",
        password: "MakaoPoker@2023_Ca11er"
    },
    {
        name: "Stream PP2",
        stream: "https://mac-str-pp.makaobet.com/pp2/ngrp:str_all/playlist.m3u8",
        url: "http://10.20.30.30:8087/v2/servers/_defaultServer_/vhosts/_defaultVHost_/applications/pp2/actions/restart",
        method: "put",
        user: "poker-rest-api-caller",
        password: "MakaoPoker@2023_Ca11er"
    }
]


/* Page Stream */
class Stream extends Component {

    /* Reload action */
    reload = data => {
        Swal.fire({
            icon: 'warning',
            title: "Вы действительно хотите перезагрузить?",
            text: "Стрим у зрителей будет приостановлено и им необходимо перезагрузить страницу",
            showCancelButton: true,
            cancelButtonColor: "#999",
            cancelButtonText: "Отменить",
            confirmButtonColor: "#03a9f4",
            confirmButtonText: "Перезагрузить",
        }).then(result => {
            if (result.isConfirmed) {

                refresh(data).then(response => {
                    if (response.status === 200) {
                        Swal.fire({ title: "Стрим успешно перезагрузился", text: JSON.stringify(response.data), icon: 'success', showConfirmButton: false, timer: 1500 })
                    }
                    else {
                        Swal.fire({ title: "Что-то пошло не так! Попробуйте позднее...", text: JSON.stringify(response.data), icon: 'error', showConfirmButton: false, timer: 1500 })
                    }
                }).catch(() => {
                    Swal.fire({ title: "Что-то пошло не так! Попробуйте позднее...", icon: 'error', showConfirmButton: false, timer: 1500 })
                })
                
            }
        })
    }

    render = () => {
        return (
            <div className="page">

                <div className="page-header">
                    <h1>Стримы</h1>
                </div>

                <div className="page-table">

                    <div className="page-table-header">
                        <div className="page-table-column number">#</div>
                        <div className="page-table-column" style={{ width: `25%` }}>Название</div>
                        <div className="page-table-column" style={{ width: `55%` }}>Стрим</div>
                        <div className="page-table-column" style={{ width: `15%` }}></div>
                    </div>

                    {DATA.map((item, index) =>
                        <div className="page-table-body" key={`${index}`}>
                            <div className="page-table-column number">{index + 1}</div>
                            <div className="page-table-column" style={{ width: `25%`, fontWeight: 1000 }}>{item.name}</div>
                            <div className="page-table-column" style={{ width: `55%` }}>{item.stream}</div>
                            <div className="page-table-column" style={{ width: `15%` }}>
                                <div className="stream-button" onClick={() => this.reload(item)}>Перезагрузить</div>
                            </div>
                        </div>
                    )}

                </div>

            </div>
        )
    }

}

export default Stream