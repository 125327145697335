import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Helpers */
import { utils, withRouter } from '../../helpers'

/* Constants */
import { env, games } from '../../constants'

/* Swal */
import Swal from 'sweetalert2'

/* Components */
import { Layout, Input, Avatar } from "../../components"

/* REST API */
import { members, create } from '../../api/Game'

/* Switch */
import Switch from "react-switch"


/* Page Create Game */
class CreateGame extends Component {

    constructor() {
        super()

        this.state = {
            status: "loading",
            data: [],
            waiting: false,

            name: "",
            slug: "",
            game: "",
            gameURL: "",
            backURL: "",
            monitorURL: "",
            hlsURL: "",
            visible: false,
            available: false,
            members: [],
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Members */
    load = (reload = false) => {

        if (reload) {
            this.setState({ status: 'loading' })
        }

        members().then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [] })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [] })
            }
            else {
                this.setState({ status: "error", data: [] })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [] })
        })

    }


    /* Find Member Action */
    find = member => {
        const { members } = this.state

        const index = members.findIndex(e => parseInt(e.id) === parseInt(member.id))

        if (index > -1) {
            return true
        }

        return false
    }

    /* Toggle Member Action */
    toggle = member => {

        const { members } = this.state

        const index = members.findIndex(e => parseInt(e.id) === parseInt(member.id))

        if (index > -1) {
            members.splice(index, 1)
        }
        else {
            members.push(member)
        }

        this.setState({ members })

    }


    /* Save action */
    save = () => {

        const { name, slug, game, gameURL, backURL, monitorURL, hlsURL, visible, available, members } = this.state
        const { navigate } = this.props

        if (name === "") {
            this._name.error("Введите название cтола")
            return
        }

        if (slug === "") {
            this._slug.error("Введите ключ cтола")
            return
        }

        if (/[^a-z0-9-]/i.test(slug)) {
            this._slug.error("Недопустимое значение")
            return
        }

        if (game === "") {
            Swal.fire(utils.notification("Выберите игру", "error"))
            return
        }

        this.setState({ waiting: true })

        create({ name, slug, game, gameURL, backURL, monitorURL, hlsURL, visible, available, members }).then(response => {
            if (response.status === 200) {
                Swal.fire(utils.notification("Игра успешно добавлена", "success")).then(() => {
                    this.setState({ waiting: false })
                    navigate(-1)
                })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ waiting: false })
                Swal.fire(utils.notification("Нет доступа!", "error"))
            }
            else if (response.status === 498) {
                this.setState({ waiting: false })
                Swal.fire(utils.notification("Неверный ключ!", "error"))
            }
            else {
                this.setState({ waiting: false })
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            }
        }).catch(() => {
            this.setState({ waiting: false })
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
        })

    }


    /* Draw button */
    _button = () => {

        const { waiting } = this.state

        if (waiting) {
            return <div className="button"><LoadingOutlined /></div>
        }

        return <div onClick={() => this.save()} className="button">Сохранить</div>
    }

    render = () => {

        const { status, data, name, slug, game, gameURL, backURL, monitorURL, hlsURL, visible, available } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Добавить игру</h1>
                </div>

                <div className="page-form">
                    <div className="form">
                        <div className="form-box">

                            <Input ref={ref => this._name = ref} label="Название стола" value={name} onChange={event => this.setState({ name: event.target.value })} />
                            <Input ref={ref => this._slug = ref} label="Ключ стола" value={slug} onChange={event => this.setState({ slug: event.target.value })} />

                            <select value={game} onChange={event => this.setState({ game: event.target.value })}>
                                <option disabled value="">Выберите игру</option>
                                {games.data.map((item, index) => <option key={`${index}`} value={item.key}>{item.name}</option>)}
                            </select>

                            <Input ref={ref => this._gameURL = ref} label="URL игры" value={gameURL} onChange={event => this.setState({ gameURL: event.target.value })} />
                            <Input ref={ref => this._backURL = ref} label="URL сервера" value={backURL} onChange={event => this.setState({ backURL: event.target.value })} />
                            <Input ref={ref => this._monitorURL = ref} label="URL монитора дилера" value={monitorURL} onChange={event => this.setState({ monitorURL: event.target.value })} />
                            <Input ref={ref => this._HLSURL = ref} label="HLS URL" value={hlsURL} onChange={event => this.setState({ hlsURL: event.target.value })} />


                            <div className="form-status-title">Статусы стола:</div>

                            <div className="form-switch">
                                <span>Видимость стола</span>
                                <Switch uncheckedIcon={false} checkedIcon={false} onColor="#8bc34a" onChange={value => this.setState({ visible: value })} checked={visible} />
                            </div>

                            <div className="form-switch">
                                <span>Стол занят</span>
                                <Switch uncheckedIcon={false} checkedIcon={false} onColor="#8bc34a" onChange={value => this.setState({ available: value })} checked={available} />
                            </div>

                        </div>

                        <div className="form-box">
                            <div className="form-member-title">Участники:</div>
                            {data.map((member, index) =>
                                <div className="form-switch form-member-box" key={`${index}`}>
                                    <div className="form-member">
                                        <Avatar uri={`${env.mediapoint}${member.image}`} />
                                        <div className="form-member-text">
                                            <div className="form-member-name">{member.name}</div>
                                            <div className="form-member-role">{member.role}</div>
                                        </div>
                                    </div>
                                    <Switch uncheckedIcon={false} checkedIcon={false} onColor="#8bc34a" onChange={() => this.toggle(member)} checked={this.find(member)} />
                                </div>
                            )}
                        </div>

                    </div>

                    <div className="form-bottom form-bottom-full">
                        {this._button()}
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(CreateGame)