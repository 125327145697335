import React, { Component } from "react"

/* Components */
import { Layout, Pagination } from '../../components'

/* REST API */
import { activity } from '../../api/Admin'

/* Helpers */
import { utils, withRouter } from '../../helpers'

/* Moment */
import moment from 'moment-timezone'
import 'moment/locale/ru'

/* Moment Settings */
moment.locale("ru")

/* Fields */
const PERCENTAGE = 95 / 4



/* Page Admin Activity */
class AdminActivity extends Component {

    constructor() {
        super()

        this.state = {
            name: null,
            data: [],
            status: "loading",
            count: 0,
            page: 1,
        }
    }


    componentDidMount = () => {
        this.load()
    }

    /* Load action */
    load = (page = 1, reload = false) => {

        const { params } = this.props
        const { id } = params

        if (id) {

            this.setState({ page })

            if (reload) {
                this.setState({ status: 'loading' })
            }

            activity({ id, page }).then(response => {
                if (response.status === 200) {
                    this.setState({ status: "ok", data: response.data.list, count: response.data.count, name: response.data.name })
                }
                else if (response.status === 401 || response.status === 403) {
                    this.setState({ status: "permission", data: [], count: 0 })
                }
                else if (response.status === 498) {
                    this.setState({ status: "key", data: [], count: 0 })
                }
                else {
                    this.setState({ status: "error", data: [], count: 0 })
                }
            }).catch(() => {
                this.setState({ status: "network", data: [], count: 0 })
            })

        }
    }

    _time = time => {

        const datetime = utils.getTime(time)

        return (
            <div className="page-table-datetime">
                <div className="page-table-time">{datetime.time}</div>
                <div className="page-table-date">{datetime.date}</div>
            </div>
        )
    }


    render = () => {

        const { status, data, page, count, name } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(1, true)} />
        }

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Активность {name ? `(${name})` : ""}</h1>
                </div>

                <div className="page-table">

                    <div className="page-table-header">
                        <div className="page-table-column number">#</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>Игра</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE * 2}%` }}>Действие</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>Дата и время</div>
                    </div>

                    {data.map((item, index) =>
                        <div className="page-table-body" key={`${index}`}>
                            <div className="page-table-column number">{(index + 1) * page}</div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>{item.table ? item.table : 'Общий'}</div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE * 2}%` }}>{utils.checkValue(item.text, "-")}</div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>{this._time(item.createdAt)} </div>
                        </div>
                    )}

                    <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page)} />

                </div>

            </div>
        )
    }

}

export default withRouter(AdminActivity)