import React, { Component } from "react"



/* Page 404 */
class Page404 extends Component {

    render = () => {
        return (
            <div className="page-404">
                <h1>Page 404</h1>
                <p>Страница, которую вы запрашивали, не существует.</p>
            </div>
        )
    }

}

export default Page404