import axios from "axios"
import { env } from '../constants'

/* Get Notification */
export const get = () => {
    const uri = `${env.startpoint}notification/get`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(data => data.response)
}


/* Notification List */
export const list = data => {
    const uri = `${env.startpoint}notification/list`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}