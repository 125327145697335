import axios from "axios"
import { env } from '../constants'


/* GET TABLE */
export const get = data => {
    const uri = `${env.gamepoint}admin/table/get`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* SAVE OMPTABLE */
export const save = data => {
    const uri = `${env.gamepoint}admin/table/save`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* REMOVE */
export const remove = data => {
    const uri = `${env.gamepoint}admin/table/remove`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* GET DEALERS OMPTABLE */
export const dealers = data => {
    const uri = `${env.gamepoint}admin/table/dealer`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Enable/Disable DEALERS OMPTABLE */
export const toggleDealer = data => {
    const uri = `${env.gamepoint}admin/table/dealer-toggle`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}


/* GET CURRENCIES OMPTABLE */
export const currency = data => {
    const uri = `${env.gamepoint}admin/table/currency`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* REMOVE CURRENCY FROM OMPTABLE */
export const removeCurrency = data => {
    const uri = `${env.gamepoint}admin/table/currency-remove`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* CREATE CURRENCY ON OMPTABLE */
export const createCurrency = data => {
    const uri = `${env.gamepoint}admin/table/currency-create`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* EDIT CURRENCY ON OMPTABLE */
export const editCurrency = data => {
    const uri = `${env.gamepoint}admin/table/currency-edit`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* SEND MESSAGE */
export const message = data => {
    const uri = `${env.gamepoint}admin/table/message`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* GET MESSAGES */
export const messages = data => {
    const uri = `${env.gamepoint}admin/table/messages`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

