import { env } from '../constants'
import { Howl } from 'howler'

/* Fields */
const sounds = [{ key: "warning", src: `${env.mediapoint}/sounds/warning.mp3` }]

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

let soundEffects = []

sounds.forEach(sound => {

    const soundEffect = new Howl({
        src: [sound.src],
        volume: 0,
        mute: true,
        loop: false,
        html5: true,
        format: ["mp3"],
        onplayerror: () => {
            soundEffect.once('unlock', () => {
                soundEffect.play()
            })
        }
    })

    soundEffects.push({ key: sound.key, src: sound.src, audio: soundEffect })

})


/* Audio */
const play = (sound = null, volume = 1) => {

    if (!sound) {
        return
    }

    if (isIOS) {
        const index = soundEffects.findIndex(e => e.key === sound)
        const soundEffect = soundEffects[index].audio
        soundEffect.stop()
        soundEffect.volume(volume)
        soundEffect.mute(false)
        soundEffect.play()

        return soundEffect
    }
    else {

        const index = sounds.findIndex(e => e.key === sound)

        if (index > -1) {
            const soundEffect = new Howl({
                autoplay: true,
                src: [sounds[index].src],
                volume,
                loop: false,
                html5: true,
                format: ["mp3"]
            })

            soundEffect.play()
            return soundEffect
        }

    }

    return
}

export { soundEffects, play }