import axios from "axios"
import { env } from '../constants'


/* List Player */
export const list = (page = 1, search = "") => {
    const uri = `${env.startpoint}player/list?page=${page}&search=${search}`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(data => data.response)
}

/* List Player */
export const toggle = data => {
    const uri = `${env.startpoint}player/change`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}


/* GET STATISTICS */
export const statistics = (player = "") => {
    const uri = `${env.startpoint}player/statistics?player=${player}`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(data => data.response)
}

/* GAME INFORMATION */
export const information = data => {
    const uri = `${env.startpoint}player/game-information`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* GAME INFORMATION */
export const periodStatistics = data => {
    const uri = `${env.startpoint}player/period`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* GET SESSIONS */
export const sessions = (player = "") => {
    const uri = `${env.startpoint}player/sessions?player=${player}`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(data => data.response)
}
