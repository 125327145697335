import React, { Component } from "react"



class Background extends Component {

    constructor() {
        super()

        this.state = {
            opacity: 0
        }
    }

    render = () => {

        const { opacity } = this.state

        return (
            <div className="background">
                <img style={{ opacity }} onLoad={() => this.setState({ opacity: 1 })} src="/images/bg.jpg" alt="Background" />
                <div className="overlay" />
            </div>
        )

    }

}

export default Background