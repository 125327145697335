import moment from 'moment-timezone'



/* Generate Dates For filter */
export const getStatisticsDate = type => {

    /* Week */
    if (type === "week") {
        const start = moment().startOf('week').tz("Greenwich").format('YYYY-MM-DD 00:00:00')
        const end = moment().endOf('week').tz("Greenwich").format('YYYY-MM-DD 23:59:59')
        return { start, end }
    }

    /* Month */
    if (type === "month") {
        const start = moment().startOf('month').tz("Greenwich").format('YYYY-MM-DD 00:00:00')
        const end = moment().endOf('month').tz("Greenwich").format('YYYY-MM-DD 23:59:59')
        return { start, end }
    }

    /* Default */
    const start = moment().tz("Greenwich").format('YYYY-MM-DD 00:00:00')
    const end = moment().tz("Greenwich").format('YYYY-MM-DD 23:59:59')

    return { start, end }
}




export const setStatisticsDate = (type, filter, start, end) => {

    if (type && filter && start && end) {

        /* Prev */
        if (type === "prev") {

            if (filter === "day") {
                const startDate = moment(new Date(start)).subtract(1, "days").tz("Greenwich").format('YYYY-MM-DD 00:00:00')
                const endDate = moment(new Date(end)).subtract(1, "days").tz("Greenwich").format('YYYY-MM-DD 23:59:59')
                return { start: startDate, end: endDate }
            }

            if (filter === "week") {
                const startDate = moment(new Date(start)).subtract(1, "weeks").startOf('week').tz("Greenwich").format('YYYY-MM-DD 00:00:00')
                const endDate = moment(new Date(end)).subtract(1, "weeks").endOf('week').tz("Greenwich").format('YYYY-MM-DD 23:59:59')
                return { start: startDate, end: endDate }
            }

            if (filter === "month") {
                const startDate = moment(new Date(start)).subtract(1, "months").startOf('month').tz("Greenwich").format('YYYY-MM-DD 00:00:00')
                const endDate = moment(new Date(end)).subtract(1, "months").endOf('month').tz("Greenwich").format('YYYY-MM-DD 23:59:59')
                return { start: startDate, end: endDate }
            }

        }

        /* Prev */
        if (type === "next") {

            if (filter === "day") {
                const startDate = moment(new Date(start)).add(1, "days").tz("Greenwich").format('YYYY-MM-DD 00:00:00')
                const endDate = moment(new Date(end)).add(1, "days").tz("Greenwich").format('YYYY-MM-DD 23:59:59')
                return { start: startDate, end: endDate }
            }

            if (filter === "week") {
                const startDate = moment(new Date(start)).add(1, "weeks").startOf('week').tz("Greenwich").format('YYYY-MM-DD 00:00:00')
                const endDate = moment(new Date(end)).add(1, "weeks").endOf('week').tz("Greenwich").format('YYYY-MM-DD 23:59:59')
                return { start: startDate, end: endDate }
            }

            if (filter === "month") {
                const startDate = moment(new Date(start)).add(1, "months").startOf('month').tz("Greenwich").format('YYYY-MM-DD 00:00:00')
                const endDate = moment(new Date(end)).add(1, "months").endOf('month').tz("Greenwich").format('YYYY-MM-DD 23:59:59')
                return { start: startDate, end: endDate }
            }

        }

    }

}


/* Get Date */
export const modifyDate = (filter, start, end) => {

    if (filter && start && end) {

        if (filter === "month" || filter === "week") {
            const startYear = moment(new Date(start)).isSame(moment(), "year") ? "" : " " + moment(new Date(start)).tz("Greenwich").format("YYYY")
            const endYear = moment(new Date(end)).isSame(moment(), "year") ? "" : " " + moment(new Date(end)).tz("Greenwich").format("YYYY")
            return moment(new Date(start)).tz("Greenwich").format("DD MMM") + startYear + " - " + moment(new Date(end)).tz("Greenwich").format("DD MMM") + endYear
        }

        if (filter === "day") {
            const today = moment(new Date(start)).isSame(moment(), 'day') ? "Сегодня, " : ""
            const yesterday = moment(new Date(start)).isSame(moment().subtract(1, 'days'), 'day') ? "Вчера, " : ""
            return `${today}${yesterday}${moment(new Date(start)).tz("Greenwich").format("DD MMM")}`
        }

    }

    return ""

}





/* Generate Dates */
export const generateDates = (filter, start, end) => {

    let list = []

    if (filter && start && end) {

        if (filter === "day") {
            const date = moment(new Date(start))
            list = [
                { name: "00:00 - 03:00", start: date.tz("Greenwich").format('YYYY-MM-DD 00:00:00'), end: date.tz("Greenwich").format('YYYY-MM-DD 03:00:00') },
                { name: "03:00 - 06:00", start: date.tz("Greenwich").format('YYYY-MM-DD 03:00:00'), end: date.tz("Greenwich").format('YYYY-MM-DD 06:00:00') },
                { name: "06:00 - 09:00", start: date.tz("Greenwich").format('YYYY-MM-DD 06:00:00'), end: date.tz("Greenwich").format('YYYY-MM-DD 09:00:00') },
                { name: "09:00 - 12:00", start: date.tz("Greenwich").format('YYYY-MM-DD 09:00:00'), end: date.tz("Greenwich").format('YYYY-MM-DD 12:00:00') },
                { name: "12:00 - 15:00", start: date.tz("Greenwich").format('YYYY-MM-DD 12:00:00'), end: date.tz("Greenwich").format('YYYY-MM-DD 15:00:00') },
                { name: "15:00 - 18:00", start: date.tz("Greenwich").format('YYYY-MM-DD 15:00:00'), end: date.tz("Greenwich").format('YYYY-MM-DD 18:00:00') },
                { name: "18:00 - 21:00", start: date.tz("Greenwich").format('YYYY-MM-DD 18:00:00'), end: date.tz("Greenwich").format('YYYY-MM-DD 21:00:00') },
                { name: "21:00 - 00:00", start: date.tz("Greenwich").format('YYYY-MM-DD 21:00:00'), end: date.tz("Greenwich").format('YYYY-MM-DD 23:59:59') },
            ]
        }

        if (filter === "week") {
            const weeks = ["Понидельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"]
            weeks.forEach((week, key) => {
                const startDate = moment(new Date(start)).add(key, 'days').tz("Greenwich").format('YYYY-MM-DD 00:00:00')
                const endDate = moment(new Date(start)).add(key, 'days').tz("Greenwich").format('YYYY-MM-DD 23:59:59')
                list.push({ name: week, start: startDate, end: endDate })
            })
        }

        if (filter === "month") {

            const startDate = moment(new Date(start)).subtract(1, 'days')
            const endDate = moment(new Date(end))

            const difference = endDate.diff(startDate, 'days')

            let count = 0
            Array.from(Array(difference).keys()).forEach(key => {

                const startPeriod = moment(new Date(start)).add(key, 'days')
                const week = startPeriod.week()

                if (week !== count) {

                    let endPeriod = moment(new Date(startPeriod)).endOf('week')

                    if (!endPeriod.isSame(startPeriod, 'months')) {
                        endPeriod = moment(new Date(startPeriod)).endOf('month')
                    }

                    const startWeek = startPeriod.tz("Greenwich").format('YYYY-MM-DD 00:00:00')
                    const endWeek = endPeriod.tz("Greenwich").format('YYYY-MM-DD 23:59:59')
                    const name = `${startPeriod.tz("Greenwich").format('DD MMM')} - ${endPeriod.tz("Greenwich").format('DD MMM')}`

                    list.push({ name, start: startWeek, end: endWeek })

                    count = week
                }
            })
        }

    }

    return list
}




/* GET DASHBOARD FILTER DATE */
export const getDashboardFilterDates = type => {

    /* Week */
    if (type === "week") {
        const start = moment().startOf('week').tz("Greenwich").format('YYYY-MM-DD 00:00:00')
        const end = moment().endOf('week').tz("Greenwich").format('YYYY-MM-DD 23:59:59')
        return { start, end }
    }

    /* Month */
    if (type === "month") {
        const start = moment().startOf('month').tz("Greenwich").format('YYYY-MM-DD 00:00:00')
        const end = moment().endOf('month').tz("Greenwich").format('YYYY-MM-DD 23:59:59')
        return { start, end }
    }

    /* Year */
    if (type === "year") {
        const start = moment().startOf('year').tz("Greenwich").format('YYYY-MM-DD 00:00:00')
        const end = moment().endOf('year').tz("Greenwich").format('YYYY-MM-DD 23:59:59')
        return { start, end }
    }

    /* Default */
    const start = moment().tz("Greenwich").format('YYYY-MM-DD 00:00:00')
    const end = moment().tz("Greenwich").format('YYYY-MM-DD 23:59:59')

    return { start, end }
}
