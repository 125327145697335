import axios from "axios"
import { env } from '../constants'


/* Refresh Stream */
export const refresh = data => {
    const uri = `${env.startpoint}stream/refresh`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}
