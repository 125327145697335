import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'


/* Component Layout */
class Layout extends Component {

    /* Draw button */
    _button = () => {

        const { reload } = this.props

        if (reload) {
            return <div onClick={() => reload()} className="button">Повторить</div>
        }
    }

    render = () => {

        const { status } = this.props

        if (status === "error") {
            return (
                <div className="inner-layout-box">
                    <img src="/images/alert.png" alt="Alert" />
                    <h2>Ошибка сервера!</h2>
                    <p>Что-то пошло не так! Попробуйте позднее.</p>
                    {this._button()}
                </div>
            )
        }

        if (status === "network") {
            return (
                <div className="inner-layout-box">
                    <img src="/images/wifi.png" alt="Wifi" />
                    <h2>Ошибка соединения!</h2>
                    <p>Проверьте подключение к сети.</p>
                    {this._button()}
                </div>
            )
        }

        if (status === "permission") {
            return (
                <div className="inner-layout-box">
                    <img src="/images/user-key.png" alt="User Key" />
                    <h2>Нет доступа!</h2>
                    <p>У вас нет доступа для просмотра данных.</p>
                    {this._button()}
                </div>
            )
        }

        if (status === "key") {
            return (
                <div className="inner-layout-box">
                    <img src="/images/key.png" alt="Key" />
                    <h2>Неверный ключ!</h2>
                    <p>Похоже ключ устарел! Перезагрузите страницу или войдите в свой аккаунт еще раз.</p>
                    <div onClick={() => window.location.reload()} className="button">Перезагрузить</div>
                </div>
            )
        }

        return (
            <div className="inner-layout-box">
                <LoadingOutlined />
            </div>
        )
    }

}

export default Layout