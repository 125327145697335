import React, { Component } from 'react'

/* Components */
import { Tag, Cards, Avatar, Pagination, Notes } from '../../components'

/* Widgets */
import { Layout } from '../Table'

/* REST API */
import { game, end, players, togglePause } from '../../api/OMP'

/* Helpers */
import { utils } from '../../helpers'

/* Sweet alert */
import Swal from "sweetalert2"

/* Widgets */
import OMPPlayer from './OMPPlayer'




/* Widget OMP Game Information */
class OMPGameInformation extends Component {

    constructor() {
        super()

        this.state = {
            table: null,
            data: null,
            total: [],
            cards: [],
            status: 'loading',

            pstatus: 'loading',
            players: [],
            count: 0,
            page: 1,
            number: '',

            visible: false,
            active: null
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Game Information */
    load = (reload = false) => {

        const { uid, gameID } = this.props

        if (!uid || !gameID) {
            this.setState({ status: 'error', data: null })
            return
        }

        if (reload) {
            this.setState({ status: "loading" })
        }

        game({ uid, gameID }).then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data.game, table: response.data.table, total: response.data.total, cards: response.data.cards })
                this.getPlayers()
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: null, table: null, total: [], cards: [] })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: null, table: null, total: [], cards: [] })
            }
            else {
                this.setState({ status: "error", data: null, table: null, total: [], cards: [] })
            }
        }).catch(() => {
            this.setState({ status: "network", data: null, table: null, total: [], cards: [] })
        })

    }

    /* Get Players */
    getPlayers = (page = 1, reload = false) => {

        const { uid, gameID } = this.props
        const { number } = this.state

        if (!uid || !gameID) {
            this.setState({ pstatus: 'error', players: [] })
            return
        }

        /* Save Page */
        this.setState({ page })

        /* Reload if need */
        if (reload) {
            this.setState({ pstatus: "loading" })
        }

        /* Get from REST API */
        players({ uid, gameID, page, player: number }).then(response => {
            if (response.status === 200) {
                this.setState({ pstatus: "ok", players: response.data.list, count: response.data.count })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ pstatus: "permission", players: [], count: 0 })
            }
            else if (response.status === 498) {
                this.setState({ pstatus: "key", players: [], count: 0 })
            }
            else {
                this.setState({ pstatus: "error", players: [], count: 0 })
            }
        }).catch(() => {
            this.setState({ pstatus: "network", players: [], count: 0 })
        })

    }

    /* Search Event */
    search = event => {
        event.preventDefault()
        this.getPlayers(1, true)
    }

    /* Set Number */
    setNumber = number => {
        if (number === "") {
            this.setState({ number }, () => this.getPlayers(1, true))
        }
        else {
            this.setState({ number })
        }
    }

    /* Get Cards */
    getCards = type => {

        /* Fields */
        const { cards } = this.state
        let list = []

        if (cards && Array.isArray(cards) && cards.length > 0) {
            cards.forEach(card => {
                if (card.type === type) {
                    list.push(card)
                }
            })
        }

        return list
    }

    /* END action */
    end = (refund = false) => {

        const { data } = this.state
        const { uid, gameID, socket } = this.props

        if (!uid || !gameID) {
            Swal.fire(utils.notification("Неизвестная ошибка! Пожалуйста, повторите попытку позже.", "error"))
            return
        }

        if (parseInt(data.status) === 1) {
            return
        }

        Swal.fire({
            title: 'Вы действительно завершить игру?',
            text: "Невозможно восстановить после завершения",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f44336',
            reverseButtons: true,
            confirmButtonText: 'Завершить',
            cancelButtonText: 'Отмена'
        }).then(result => {
            if (result.isConfirmed) {
                end({ uid, gameID, refund }).then(response => {
                    if (response.status === 200) {
                        if (response.data.update === "yes") {
                            socket.emit("end", { refund })
                        }
                        this.load(true)
                        Swal.fire(utils.notification("Завершение выполнено!", "success"))
                    }
                    else if (response.status === 401 || response.status === 403) {
                        Swal.fire(utils.notification("Нет доступа!", "error"))
                    }
                    else if (response.status === 498) {
                        Swal.fire(utils.notification("Неверный ключ!", "error"))
                    }
                    else {
                        Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
                    }
                }).catch(() => {
                    Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
                })
            }
        })


    }



    /* Pause Game */
    pause = () => {

        const { data } = this.state
        const { socket, uid } = this.props

        if (parseInt(data.status) === 1) {
            Swal.fire(utils.notification("Игра завершена!", "error"))
            return
        }

        if (parseInt(data.isPaused) === 1) {
            Swal.fire(utils.notification("Игра уже на паузе!", "error"))
            return
        }

        Swal.fire({
            title: 'Вы действительно хотите сделать паузу?',
            text: "Игра будет приостановлена",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2196f3',
            reverseButtons: true,
            confirmButtonText: 'Да, приостановить',
            cancelButtonText: 'Отмена'
        }).then(result => {
            if (result.isConfirmed) {
                togglePause({ uid, gameID: data.id, type: "pause" }).then(response => {
                    if (response.status === 200) {
                        if (response.data.update === "yes") {
                            socket.emit("pause", { from: "support" })
                            Swal.fire(utils.notification("Игра на паузе", "success", 3000))
                        }
                        else {
                            Swal.fire(utils.notification("Внимание! Статус изменен только<br /> в базе данных, так как<br /> эта не текущая игра!", "success", 4000))
                        }

                        this.load(true)
                    }
                    else {
                        Swal.fire(utils.notification("Что-то пошло не так! Попробуйте позднее!", "error"))
                    }
                }).catch(() => {
                    Swal.fire(utils.notification("Что-то пошло не так! Попробуйте позднее!", "error"))
                })
            }
        })

    }

    /* Continue Game */
    continue = () => {

        const { data } = this.state
        const { socket, uid } = this.props

        if (parseInt(data.status) === 1) {
            Swal.fire(utils.notification("Игра завершена!", "error"))
            return
        }

        if (parseInt(data.isPaused) === 0) {
            Swal.fire(utils.notification("Игра не на паузе!", "error"))
            return
        }

        Swal.fire({
            title: 'Вы действительно хотите продолжить?',
            text: "Игра будет продолжаться без изменении",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2196f3',
            reverseButtons: true,
            confirmButtonText: 'Да, продолжить',
            cancelButtonText: 'Отмена'
        }).then(result => {
            if (result.isConfirmed) {
                togglePause({ uid, gameID: data.id, type: "continue" }).then(response => {
                    if (response.status === 200) {

                        if (response.data.update === "yes") {
                            socket.emit("continue", { type: "continue", gameID: data.id })
                            Swal.fire(utils.notification("Игра продолжается", "success", 3000))
                        }
                        else {
                            Swal.fire(utils.notification("Внимание! Статус изменен только<br /> в базе данных, так как<br /> эта не текущая игра!", "success", 3000))
                        }

                        this.load(true)
                    }
                    else {
                        Swal.fire(utils.notification("Что-то пошло не так! Попробуйте позднее!", "error"))
                    }
                }).catch(() => {
                    Swal.fire(utils.notification("Что-то пошло не так! Попробуйте позднее!", "error"))
                })
            }
        })

    }

    /* Revoke Cards */
    revoke = () => {

        const { data } = this.state
        const { socket, uid } = this.props

        if (parseInt(data.status) === 1) {
            Swal.fire(utils.notification("Игра завершена!", "error"))
            return
        }

        if (parseInt(data.isPaused) === 0) {
            Swal.fire(utils.notification("Игра не на паузе!", "error"))
            return
        }

        Swal.fire({
            title: 'Вы действительно хотите продолжить?',
            text: "Карты будут сброшены",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2196f3',
            reverseButtons: true,
            confirmButtonText: 'Да, сбросить',
            cancelButtonText: 'Отмена'
        }).then(result => {
            if (result.isConfirmed) {
                togglePause({ uid, gameID: data.id, type: "continue" }).then(response => {
                    if (response.status === 200) {

                        if (response.data.update === "yes") {
                            socket.emit("continue", { type: "revoke", gameID: data.id })
                            Swal.fire(utils.notification("Карты сброшены", "success", 3000))
                        }
                        else {
                            Swal.fire(utils.notification("Внимание! Статус изменен только<br /> в базе данных, так как<br /> эта не текущая игра!", "success", 3000))
                        }

                        this.load(true)
                    }
                    else {
                        Swal.fire(utils.notification("Что-то пошло не так! Попробуйте позднее!", "error"))
                    }
                }).catch(() => {
                    Swal.fire(utils.notification("Что-то пошло не так! Попробуйте позднее!", "error"))
                })
            }
        })

    }


    /* Draw time */
    _time = time => {

        const datetime = utils.getTime(time)

        return (
            <div className="page-table-datetime">
                <div className="page-table-time">{datetime.time}</div>
                <div className="page-table-date">{datetime.date}</div>
            </div>
        )
    }


    /* Draw Total */
    _total = () => {

        const { total } = this.state

        if (total && Array.isArray(total) && total.length > 0) {
            return (
                <div>
                    {total.map((item, index) => {

                        let total = 0

                        if (item.debit && item.debit.total) {
                            total = total + parseFloat(item.debit.total)
                        }

                        if (item.credit && item.credit.total) {
                            total = total - parseFloat(item.credit.total)
                        }

                        return (
                            <div className="omp--game-information-item" key={`${index}`}>
                                <img src="/images/game/calculate.png" alt="Calculate" />
                                <div className="omp--game-information-item-box">
                                    <div className="omp--game-information-item-title">Итого <span>{item.name} ({item.symbol})</span></div>

                                    {item.debit && item.debit.total &&
                                        <div className="omp--game-information-item-image-text">
                                            <img src="/images/game/increase.png" alt="Debit" />
                                            <b>{utils.convertor(item.debit.total, item.symbol, item.isAfter)}</b>
                                        </div>
                                    }

                                    {item.credit && item.credit.total &&
                                        <div className="omp--game-information-item-image-text">
                                            <img src="/images/game/decrease.png" alt="Credit" />
                                            <b>{utils.convertor(item.credit.total, item.symbol, item.isAfter)}</b>
                                        </div>
                                    }

                                    <div className={`omp--game-information-item-text ${total >= 0 ? "green" : 'red'}`}>
                                        {total >= 0 ? "+ " : "- "} {utils.convertor(Math.abs(total), item.symbol, item.isAfter)}
                                    </div>

                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        }
    }


    /* Draw players */
    _players = () => {

        const { number, pstatus, players, page, count } = this.state

        if (pstatus !== 'ok') {
            return (
                <div className="game--players">

                    {/* Players Head */}
                    <div className="game--players-head">

                        <div className="game--players-head-title">Players</div>

                        <form onSubmit={this.search} className="game--players-head-form">
                            <input placeholder="Поиск по номеру игрока" value={number} onChange={event => this.setState({ number: event.target.value })} />
                            <button onClick={() => this.getPlayers(1, true)}><img src="/images/search.png" alt="Search" /></button>
                        </form>

                    </div>

                    <Layout status={pstatus} reload={() => this.getPlayers(1, true)} />

                </div>
            )
        }

        return (
            <div className="game--players">

                {/* Players Head */}
                <div className="game--players-head">

                    <div className="game--players-head-title">Players</div>

                    <form onSubmit={this.search} className="game--players-head-form">
                        <input placeholder="Поиск по номеру игрока" value={number} onChange={event => this.setNumber(event.target.value)} />
                        <button onClick={() => this.getPlayers(1, true)}><img src="/images/search.png" alt="Search" /></button>
                    </form>

                </div>

                {players && Array.isArray(players) && players.length > 0 &&
                    <div className="game--players-container">
                        {players.map((item, index) =>
                            <div onClick={() => this.setState({ visible: true, active: item })} className="game--players-element" key={`${index}`}>
                                <div className="game--players-element-inner">

                                    <div className="game--players-element-user">
                                        <Avatar uri="/images/player/gamer.png" alt="Gamer" />
                                        <div className="game--players-element-text">
                                            <div className="game--players-element-name">{item.firstName}</div>
                                            <div className="game--players-element-code">{item.player}</div>
                                        </div>
                                    </div>

                                    <div className="game--players-element-item">
                                        <img src="/images/game/dollar-coin.png" alt="Coin" />
                                        <div className="game--players-element-item-text">
                                            <div className="game--players-element-item-name">Баланс</div>
                                            <div className="game--players-element-item-value">{item.startBalance ? utils.convertor(item.startBalance, item.symbol, item.isAfter) : "-"}</div>
                                        </div>
                                    </div>

                                    <div className="game--players-element-item">
                                        <img src="/images/game/dollar-coin.png" alt="Coin" />
                                        <div className="game--players-element-item-text">
                                            <div className="game--players-element-item-name">ANTE</div>
                                            <div className="game--players-element-item-value">{item.ante ? utils.convertor(item.ante, item.symbol, item.isAfter) : "-"}</div>
                                        </div>
                                    </div>

                                    <div className="game--players-element-item">
                                        <img src="/images/game/dollar-coin.png" alt="Coin" />
                                        <div className="game--players-element-item-text">
                                            <div className="game--players-element-item-name">Общая ставка</div>
                                            <div className="game--players-element-item-value">{item.debit ? utils.convertor(item.debit, item.symbol, item.isAfter) : "-"}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>
                }

                {players && Array.isArray(players) && players.length === 0 && <div className="game--players-empty">Пусто</div>}

                <Pagination size={10} page={page} total={count} onPageChange={page => this.getPlayers(page, true)} />

            </div>
        )
    }


    render = () => {

        const { status, table, data, visible, active } = this.state
        const { gameID, uid, socket } = this.props

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        /* Game Fields */
        const datetime = utils.getTime(data.createdAt)

        return (
            <div className="omp--game-inner">
                <div className="omp--game-information">
                    <div className='omp--game-information-row'>

                        {/* Game Information */}
                        <div className='omp--game-information-element'>

                            {/* Name */}
                            <div className="omp--game-information-item">
                                <img src="/images/game/spades.png" alt="Spades" />
                                <div className="omp--game-information-item-box">
                                    <div className="omp--game-information-item-title">Игра</div>
                                    <div className="omp--game-information-item-text">{table.name}</div>
                                </div>
                            </div>

                            {/* Number */}
                            <div className="omp--game-information-item">
                                <img src="/images/game/shape.png" alt="Shape" />
                                <div className="omp--game-information-item-box">
                                    <div className="omp--game-information-item-title">Номер игры</div>
                                    <div className="omp--game-information-item-text">{data.number}</div>
                                </div>
                            </div>

                            {/* Date */}
                            <div className="omp--game-information-item">
                                <img src="/images/game/clock.png" alt="Clock" />
                                <div className="omp--game-information-item-box">
                                    <div className="omp--game-information-item-title">Дата</div>
                                    <div className="omp--game-information-item-text">{datetime.time}, <span>{datetime.date}</span></div>
                                </div>
                            </div>

                            {/* Dealer */}
                            <div className="omp--game-information-item">
                                <img src="/images/game/dealer.png" alt="Dealer" />
                                <div className="omp--game-information-item-box">
                                    <div className="omp--game-information-item-title">Дилер</div>
                                    <div className="omp--game-information-item-text">{data.dealer}</div>
                                </div>
                            </div>

                            {/* Game Status */}
                            <div className="omp--game-information-item">
                                <img src="/images/game/flag.png" alt="Flag" />
                                <div className="omp--game-information-item-box">
                                    <div className="omp--game-information-item-title">Статус игры</div>
                                    {parseInt(data.status) === 1 ? <Tag color="blue">Завершен</Tag> : <Tag color="purple">Не завершен</Tag>}
                                </div>
                            </div>

                            {/* Pause Status */}
                            <div className="omp--game-information-item">
                                <img src="/images/game/pause.png" alt="Pause" />
                                <div className="omp--game-information-item-box">
                                    <div className="omp--game-information-item-title">Статус паузы</div>
                                    {parseInt(data.isPaused) === 1 ? <Tag color="red">На паузе</Tag> : <div className="omp--game-information-item-text">-</div>}
                                </div>
                            </div>

                            {/* Update Status */}
                            <div className="omp--game-information-item">
                                <img src="/images/game/tap.png" alt="Tap" />
                                <div className="omp--game-information-item-box">
                                    <div className="omp--game-information-item-title">Статус изменении</div>
                                    {parseInt(data.isUpdated) === 1 ? <Tag color="pink">Изменен</Tag> : <div className="omp--game-information-item-text">-</div>}
                                </div>
                            </div>

                        </div>


                        {/* Player Information */}
                        <div className="omp--game-information-element">

                            {/* Players Count */}
                            <div className="omp--game-information-item">
                                <img src="/images/game/group.png" alt="Group" />
                                <div className="omp--game-information-item-box">
                                    <div className="omp--game-information-item-title">Количество игроков</div>
                                    <div className="omp--game-information-item-text">{data.players}</div>
                                </div>
                            </div>

                            {/* Transactions Count */}
                            <div className="omp--game-information-item">
                                <img src="/images/game/transaction.png" alt="Transaction" />
                                <div className="omp--game-information-item-box">
                                    <div className="omp--game-information-item-title">Количество транзакции</div>
                                    <div className="omp--game-information-item-text">{data.transactions}</div>
                                </div>
                            </div>

                            {/* Total */}
                            {this._total()}

                        </div>

                        {/* Actions */}
                        <div className="omp--game-information-big-element">

                            <div className="omp--game-information-text">Game actions</div>

                            <div className="omp--game-buttons">
                                <div onClick={() => this.end()} className={`omp--game-button ${parseInt(data.status) === 1 ? 'disabled' : ''}`} >Завершить игру</div>
                                <div onClick={() => this.end(true)} className={`omp--game-button gray ${parseInt(data.status) === 1 ? 'disabled' : ''}`} >Завершить и вернуть ставку</div>
                            </div>

                            <div className="evp--game-information-text">Pause actions</div>

                            <div className="evp--game-buttons">
                                <div onClick={() => this.pause()} className={`evp--game-button blue ${parseInt(data.isPaused) === 1 ? 'disabled' : ''}`} >Приостановить игру</div>
                                <div onClick={() => this.revoke()} className={`evp--game-button darkblue ${parseInt(data.isPaused) === 0 ? 'disabled' : ''}`} >Сбросить карты</div>
                                <div onClick={() => this.continue()} className={`evp--game-button green ${parseInt(data.isPaused) === 0 ? 'disabled' : ''}`} >Продолжить без изменении</div>
                            </div>

                        </div>

                    </div>


                    {/* Draw Players */}
                    {this._players()}

                    {/* Cards */}
                    <div className="cards--container">
                        <Cards title="Dealer Cards" cards={this.getCards("dealer")} />
                        <Cards title="Player Cards" cards={this.getCards("player")} />
                    </div>

                </div>

                {active && <OMPPlayer visible={visible} close={() => this.setState({ visible: false }, () => setTimeout(() => this.setState({ visible: false, active: null }), 200))} active={active} gameID={gameID} uid={uid} socket={socket} />}

                <Notes uid={table.uid} gameID={data.id} />

            </div>
        )
    }

}

export default OMPGameInformation