import axios from "axios"
import { env } from '../constants'


/* List Game Tables */
export const tables = () => {
    const uri = `${env.startpoint}game/tables`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(data => data.response)
}

/* List Members */
export const members = () => {
    const uri = `${env.startpoint}game/members`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(data => data.response)
}

/* Create */
export const create = data => {
    const uri = `${env.startpoint}game/create`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

