import React, { Component } from "react"

/* Components */
import { Layout, Input } from "../../components"

/* REST API */
import { roles, create } from '../../api/Admin'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Helpers */
import { utils, withRouter } from "../../helpers"

/* Swal */
import Swal from 'sweetalert2'



/* Page Admin Create */
class AdminCreate extends Component {

    constructor() {
        super()

        this.state = {
            status: "loading",
            data: [],
            name: "",
            email: "",
            password: "",
            roleID: 0,
            waiting: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Roles */
    load = (reload = false) => {

        if (reload) {
            this.setState({ status: 'loading' })
        }

        roles().then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [] })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [] })
            }
            else {
                this.setState({ status: "error", data: [] })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [] })
        })

    }

    /* Save action */
    save = () => {

        const { name, email, password, roleID } = this.state
        const { navigate } = this.props

        if (name === "" || name === null || name === undefined) {
            this._name.error("Введите свое имя")
            return
        }

        if (email === "" || email === null || email === undefined) {
            this._email.error("Введите Email")
            return
        }

        if (/\S+@\S+\.\S+/.test(email) === false) {
            this._email.error("Неверный формат Email")
            return
        }

        if (password === "" || password === null || password === undefined) {
            this._password.error("Введите пароль")
            return
        }

        if (password.length < 8) {
            this._password.error("Пароль должен быть больше 8 символов")
            return
        }

        if (roleID === 0) {
            Swal.fire(utils.notification("Выберите роль", "error"))
            return
        }

        this.setState({ waiting: true })

        create({ name, email, password, roleID }).then(response => {
            if (response.status === 200) {
                Swal.fire(utils.notification("Пользователь успешно добавлен", "success")).then(() => {
                    navigate(-1)
                })
            }
            else if (response.status === 401 || response.status === 403) {
                Swal.fire(utils.notification("Нет доступа!", "error"))
            }
            else if (response.status === 498) {
                Swal.fire(utils.notification("Неверный ключ!", "error"))
            }
            else {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
        }).finally(() => {
            this.setState({ waiting: false })
        })


    }

    /* Draw button */
    _button = () => {

        const { waiting } = this.state

        if (waiting) {
            return <div className="button"><LoadingOutlined /></div>
        }

        return <div onClick={() => this.save()} className="button">Сохранить</div>
    }

    render = () => {

        const { status, name, email, password, roleID, data } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Создать пользователя</h1>
                </div>

                <div className="page-form">

                    <div className="form">
                        <div className="form-box">
                            <Input ref={ref => this._name = ref} label="Название" value={name} onChange={event => this.setState({ name: event.target.value })} />
                            <Input ref={ref => this._email = ref} label="Email" value={email} onChange={event => this.setState({ email: event.target.value })} />
                            <select value={roleID} onChange={event => this.setState({ roleID: event.target.value })}>
                                <option disabled value={0}>Выберите роль</option>
                                {data.map((item, index) => <option key={`${index}`} value={item.id}>{item.name}</option>)}
                            </select>
                            <Input ref={ref => this._password = ref} label="Пароль" value={password} onChange={event => this.setState({ password: event.target.value })} secure />
                            <div onClick={() => this.setState({ password: utils.generatePassword() })} className="form-generate-password">Сгенерировать пароль</div>
                        </div>
                    </div>

                    <div className="form-bottom">
                        {this._button()}
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(AdminCreate)