import React, { Component } from "react"

/* Components */
import { Layout } from '../../components'

/* REST API */
import { games, changeAccept } from '../../api/Operator'

/* Helpers */
import { withRouter, utils } from "../../helpers"

/* Switch */
import Switch from "react-switch"

/* Swal */
import Swal from 'sweetalert2'


/* Page Operator Accept */
class OperatorAccept extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            status: "loading",
            operator: null,
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Roles */
    load = (reload = false) => {

        const { params } = this.props
        const { id } = params

        if (id) {

            if (reload) {
                this.setState({ status: 'loading' })
            }

            games({ id }).then(response => {
                if (response.status === 200) {
                    this.setState({ status: "ok", operator: response.data.operator, data: response.data.data })
                }
                else if (response.status === 401 || response.status === 403) {
                    this.setState({ status: "permission", data: [] })
                }
                else if (response.status === 498) {
                    this.setState({ status: "key", data: [] })
                }
                else {
                    this.setState({ status: "error", data: [] })
                }
            }).catch(() => {
                this.setState({ status: "network", data: [] })
            })

        }
        else {
            this.setState({ status: "error" })
        }

    }


    /* Get Status */
    getStatus = item => {

        const { data } = this.state
        const index = data.findIndex(e => parseInt(e.id) === parseInt(item.id))

        if (index > -1) {
            if (data[index] && parseInt(data[index].enabled) === 1) {
                return true
            }
        }

        return false
    }


    /* Change Status */
    changeStatus = (item, value) => {

        const { data } = this.state
        const { params } = this.props
        const { id } = params

        if (id) {
            changeAccept({ id, tableID: item.id, value }).then(response => {
                if (response.status === 200) {

                    const index = data.findIndex(e => parseInt(e.id) === parseInt(item.id))

                    if (index > -1) {

                        if (value) {
                            data[index].enabled = 1
                        }
                        else {
                            data[index].enabled = 0
                        }

                        this.setState({ data })
                    }

                    Swal.fire(utils.notification("Данные успешно сохранены!", "success"))

                }
                else if (response.status === 401 || response.status === 403) {
                    Swal.fire(utils.notification("Нет доступа!", "error"))
                }
                else if (response.status === 498) {
                    Swal.fire(utils.notification("Неверный ключ!", "error"))
                }
                else {
                    Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
                }
            }).catch(() => {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            })
        }



    }


    render = () => {

        const { status, data, operator } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Изменить доступы для {operator ? operator.name : ""}</h1>
                </div>

                <div className="page-form">
                    <div className="form">
                        <div className="form-box">
                            {data.map((item, index) =>
                                <div className="form-game-item" key={`${index}`}>
                                    <div className="form-game-item-text">
                                        <div className="form-game-item-name">{item.name}</div>
                                        <div className="form-game-item-slug">{item.slug}</div>
                                    </div>
                                    <Switch uncheckedIcon={false} checkedIcon={false} onColor="#8bc34a" onChange={value => this.changeStatus(item, value)} checked={this.getStatus(item)} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            </div>
        )
    }

}

export default withRouter(OperatorAccept)