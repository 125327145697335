import React, { Component } from "react"

/* Widgets */
import { Layout } from '../Table'

/* REST API */
import { statistics, periodStatistics } from '../../api/OEP'

/* Helpers */
import { utils, datetime } from "../../helpers"

/* Chart */
import { AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, ResponsiveContainer } from 'recharts'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'


/* Widget OEP Statistics */
class OEPStatistics extends Component {

    constructor() {
        super()

        const date = datetime.getStatisticsDate("day")

        this.state = {
            data: null,
            status: 'loading',

            filter: "day",
            start: date.start,
            end: date.end,
            statistics: null,
            chart: [],
            waiting: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Game Information */
    load = (reload = false) => {

        /* Fields */
        const { uid } = this.props

        /* Validate */
        if (!uid) {
            this.setState({ status: 'error', data: null })
            return
        }

        /* Reload */
        if (reload) {
            this.setState({ status: "loading" })
        }

        /* Send request to REST API */
        statistics({ uid }).then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data })
                this.statistics()
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: null })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: null })
            }
            else {
                this.setState({ status: "error", data: null })
            }
        }).catch(() => {
            this.setState({ status: "network", data: null })
        })

    }



    /* DRAW GAME INFORMATION WIDGET */
    _information = () => {

        const { data } = this.state

        return (
            <div className="statistics-information-widget">

                {/* Player */}
                {data.table &&
                    <div className="statistics-information-widget-user">
                        <img src="/images/player/spades.png" alt="Spades" />
                        <div className="statistics-information-widget-user-text">
                            <div className="statistics-information-widget-user-name">{utils.checkValue(data.table.name, "-")}</div>
                            <div className="statistics-information-widget-user-code">{utils.checkValue(data.table.slug, "-")}</div>
                        </div>
                    </div>
                }

                {/* Players Count */}
                <div className="statistics-information-widget-element">
                    <img src="/images/person.png" alt="Person" />
                    <div className="statistics-information-widget-element-text">
                        <div className="statistics-information-widget-element-name">Количество Игроков</div>
                        <div className="statistics-information-widget-element-value">{utils.checkValue(data.player, "0")}</div>
                    </div>
                </div>

                {/* Games Count */}
                <div className="statistics-information-widget-element">
                    <img src="/images/player/poker-chip.png" alt="Poker Chip" />
                    <div className="statistics-information-widget-element-text">
                        <div className="statistics-information-widget-element-name">Количество ставок ANTE</div>
                        <div className="statistics-information-widget-element-value">{utils.checkValue(data.count, "0")}</div>
                    </div>
                </div>

                {/* Total Time */}
                <div className="statistics-information-widget-element">
                    <img src="/images/player/back-in-time.png" alt="Time" />
                    <div className="statistics-information-widget-element-text">
                        <div className="statistics-information-widget-element-name">Общее время</div>
                        <div className="statistics-information-widget-element-value">{utils.converTime(data.time)}</div>
                    </div>
                </div>

                {/* DEBIT Amount */}
                <div className="statistics-information-widget-element">
                    <img src="/images/player/increase.png" alt="Increase" />
                    <div className="statistics-information-widget-element-text">
                        <div className="statistics-information-widget-element-name">DEBIT:</div>
                        <div className="statistics-information-widget-element-value">
                            <img src="/images/similar.png" alt="Calculate" />
                            {utils.convertor(parseFloat(Math.abs(data.totalDebit)), "$", 0, " ")}
                        </div>
                    </div>
                </div>

                {/* CREDIT Amount */}
                <div className="statistics-information-widget-element">
                    <img src="/images/player/decrease.png" alt="Decrease" />
                    <div className="statistics-information-widget-element-text">
                        <div className="statistics-information-widget-element-name">CREDIT:</div>
                        <div className="statistics-information-widget-element-value">
                            <img src="/images/similar.png" alt="Calculate" />
                            {utils.convertor(parseFloat(Math.abs(data.totalCredit)), "$", 0, " ")}
                        </div>
                    </div>
                </div>

                {/* Total Amount */}
                <div className="statistics-information-widget-element">
                    <img src="/images/player/stock-exchange-app.png" alt="Time" />
                    <div className="statistics-information-widget-element-text">
                        <div className="statistics-information-widget-element-name">ПРИБЫЛЬ:</div>
                        <div className={`statistics-information-widget-element-value ${data.total >= 0 ? 'green' : 'red'}`}>
                            {data.total >= 0 ? <img src="/images/similar-green.png" alt="Calculate" /> : <img src="/images/similar-red.png" alt="Calculate" />}
                            {data.total > 0 && "+ "}
                            {data.total < 0 && "- "}
                            {utils.convertor(parseFloat(Math.abs(data.total)), "$", 0, " ")}
                        </div>
                    </div>
                </div>





                {/* CURRENCY NUMBERS */}
                {data && data.numbers && Array.isArray(data.numbers) && data.numbers.length > 0 && data.currencies && Array.isArray(data.currencies) && data.currencies.length > 0 &&
                    <div className="statistics-information-numbers">
                        {data.numbers.map((item, i) => {

                            const index = data.currencies.findIndex(e => String(e.code).toLowerCase() === String(item.code).toLowerCase())

                            let current = data.currencies[0]
                            if (index > - 1) {
                                current = data.currencies[index]
                            }

                            if (item.debit - item.credit !== 0) {

                                return (
                                    <div key={`${i}`}>
                                        <div className="statistics-information-widget-currency">{current.code}</div>
                                        <div className="statistics-information-widget-item">
                                            <div className="statistics-information-widget-element">
                                                <img src="/images/player/poker-chip.png" alt="Poker Chip" />
                                                <div className="statistics-information-widget-element-text">
                                                    <div className="statistics-information-widget-element-name">Средняя ставка ANTE ({current.code})</div>
                                                    <div className="statistics-information-widget-element-value">{utils.convertor(parseFloat(item.ante), utils.checkValue(current.symbol, "$"), utils.checkValue(current.isAfter, 0), " ")}</div>
                                                </div>
                                            </div>
                                            <div className="statistics-information-widget-element">
                                                <img src="/images/player/increase.png" alt="Increase" />
                                                <div className="statistics-information-widget-element-text">
                                                    <div className="statistics-information-widget-element-name">DEBIT ({current.code})</div>
                                                    <div className="statistics-information-widget-element-value">{utils.convertor(parseFloat(item.debit), utils.checkValue(current.symbol, "$"), utils.checkValue(current.isAfter, 0), " ")}</div>
                                                </div>
                                            </div>
                                            <div className="statistics-information-widget-element">
                                                <img src="/images/player/decrease.png" alt="Decrease" />
                                                <div className="statistics-information-widget-element-text">
                                                    <div className="statistics-information-widget-element-name">CREDIT ({current.code})</div>
                                                    <div className="statistics-information-widget-element-value">{utils.convertor(parseFloat(item.credit), utils.checkValue(current.symbol, "$"), utils.checkValue(current.isAfter, 0), " ")}</div>
                                                </div>
                                            </div>
                                            <div className="statistics-information-widget-element">
                                                <img src="/images/player/calculate.png" alt="Calculate" />
                                                <div className="statistics-information-widget-element-text">
                                                    <div className="statistics-information-widget-element-name">TOTAL ({current.code})</div>
                                                    <div className={`statistics-information-widget-element-value ${item.debit - item.credit >= 0 ? 'green' : 'red'}`}>
                                                        {item.debit - item.credit > 0 && "+ "}
                                                        {item.debit - item.credit < 0 && "- "}
                                                        {utils.convertor(parseFloat(Math.abs(item.debit - item.credit)), utils.checkValue(current.symbol, "$"), utils.checkValue(current.isAfter, 0), " ")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            return <div key={`${index}`} />
                        })}
                    </div>
                }

            </div>
        )
    }

    /* DRAW COMBINATIONS */
    _combinations = () => {

        const { data } = this.state

        return (
            <div className="statistics-information-combinations">

                <h3>Комбинации</h3>

                {data.combination && Array.isArray(data.combination) && data.combination.length > 0 && data.combination.map((combination, index) =>
                    <div className="statistics-information-combination" key={`${index}`}>
                        <div className="statistics-information-combination-name">{combination.result}</div>
                        <div className="statistics-information-combination-value">{combination.count}</div>
                    </div>
                )}

            </div>
        )
    }


    /* Load Statistics Date */
    statistics = () => {

        /* Fields */
        const { uid } = this.props
        const { filter, start, end } = this.state

        /* Start Loading */
        this.setState({ waiting: true, chart: [], statistics: null })

        /* Get Generated Dates */
        const dates = datetime.generateDates(filter, start, end)

        /* Load Statistics */
        periodStatistics({ uid, start, end, dates }).then(response => {
            console.log(response.data)
            if (response.status === 200) {
                this.setState({ waiting: false, statistics: response.data.statistics, chart: response.data.chart })
            }
        })
    }

    /* Set Filter */
    setFilter = type => {

        const { filter } = this.state

        if (filter !== type) {
            const date = datetime.getStatisticsDate(type)
            this.setState({ filter: type, start: date.start, end: date.end }, () => this.statistics())
        }
    }

    /* Go action */
    go = type => {

        const { filter, start, end } = this.state
        const date = datetime.setStatisticsDate(type, filter, start, end)

        if (date) {
            this.setState({ start: date.start, end: date.end }, () => this.statistics())
        }

    }


    /* Type Formatter */
    formatter = (value, name) => {

        if (name === "debit") {
            return [<div className="similar-text"><img src="/images/similar-green.png" alt="Similar" /> {utils.convertor(value, "$", 0, "\u0020", "-")}</div>, "DEBIT"]
        }

        if (name === "credit") {
            return [<div className="similar-text"><img src="/images/similar-red.png" alt="Similar" /> {utils.convertor(value, "$", 0, "\u0020", "-")}</div>, "CREDIT"]
        }

    }

    /* DRAW CHART */
    _chart = () => {

        const { filter, start, end, waiting, chart, statistics } = this.state

        return (
            <div className="statistics-information-chart">

                {/* CHART HEAD */}
                <div className="chart-content-statistics-head">
                    <div className="chart-content-statistics-title">Статистика за период</div>
                    <div className="chart-content-statistics-head-filter">
                        <div onClick={() => this.setFilter("day")} className={`chart-content-statistics-head-filter-item ${filter === "day" ? "active" : "disabled"}`}>День</div>
                        <div onClick={() => this.setFilter("week")} className={`chart-content-statistics-head-filter-item ${filter === "week" ? "active" : "disabled"}`}>Неделя</div>
                        <div onClick={() => this.setFilter("month")} className={`chart-content-statistics-head-filter-item ${filter === "month" ? "active" : "disabled"}`}>Месяц</div>
                    </div>
                </div>

                {/* DASHBOARD CHART FILTER */}
                <div className="chart-content-statistics-filter">
                    <div onClick={() => this.go("prev")} className="chart-content-statistics-filter-prev">
                        <img src="/images/left-arrow.png" alt="Left" />
                    </div>
                    <div className="chart-content-statistics-filter-value">{datetime.modifyDate(filter, start, end)}</div>
                    <div onClick={() => this.go("next")} className="chart-content-statistics-filter-next">
                        <img src="/images/right-arrow.png" alt="Right" />
                    </div>
                </div>


                {/* DASHBOARD CHART */}
                <div className="chart-content-statistics-container">


                    <div className="chart-content-statistics-container-inner">
                        <ResponsiveContainer>
                            <AreaChart data={chart} margin={{ left: -20, right: 30 }} style={{ opacity: waiting ? 0.8 : 1 }}>
                                <defs>
                                    <linearGradient id="colorRed" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#f44336" stopOpacity={0.1} />
                                        <stop offset="95%" stopColor="#f44336" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="colorGreen" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#74c70a" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#74c70a" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <CartesianGrid strokeDasharray="2 2" />
                                <XAxis dataKey="name" tick={{ stroke: '#141414', strokeWidth: 0.2, fontSize: 10 }} />
                                <YAxis color="#fff" tick={{ stroke: '#141414', strokeWidth: 0.2, fontSize: 12 }} />
                                <Tooltip formatter={(value, name) => this.formatter(value, name)} />
                                <Area type="monotone" dataKey="credit" stroke="#f44336" strokeWidth={2} fill="url(#colorRed)" />
                                <Area type="monotone" dataKey="debit" stroke="#74c70a" strokeWidth={2} fill="url(#colorGreen)" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>

                    {waiting && <LoadingOutlined />}

                    {statistics && <div className="chart-content-statistics-value">Статистика за {datetime.modifyDate(filter, start, end)}</div>}

                    {statistics &&
                        <div className="chart-content-statistics-information">

                            <div className="chart-content-statistics-information-item">
                                <img src="/images/person.png" alt="Person" />
                                <div className="chart-content-statistics-information-box">
                                    <div className="chart-content-statistics-information-text">Количество Игроков</div>
                                    <div className="chart-content-statistics-information-value">{utils.checkValue(statistics.player, "0")}</div>
                                </div>
                            </div>

                            <div className="chart-content-statistics-information-item">
                                <img src="/images/player/poker-chip.png" alt="Poker Chip" />
                                <div className="chart-content-statistics-information-box">
                                    <div className="chart-content-statistics-information-text">Количество ставок ANTE</div>
                                    <div className="chart-content-statistics-information-value">{utils.checkValue(statistics.count, "0")}</div>
                                </div>
                            </div>

                            <div className="chart-content-statistics-information-item">
                                <img src="/images/player/back-in-time.png" alt="Time" />
                                <div className="chart-content-statistics-information-box">
                                    <div className="chart-content-statistics-information-text">Общее время</div>
                                    <div className="chart-content-statistics-information-value">{utils.converTime(statistics.time)}</div>
                                </div>
                            </div>

                            <div className="chart-content-statistics-information-item">
                                <img src="/images/player/calculate.png" alt="Calculate" />
                                <div className="chart-content-statistics-information-box">
                                    <div className="chart-content-statistics-information-text">TOTAL</div>
                                    <div className="chart-content-statistics-information-value">
                                        <img src="/images/similar.png" alt="Calculate" />
                                        {statistics.total > 0 && "+ "}
                                        {statistics.total < 0 && "- "}
                                        {utils.convertor(parseFloat(Math.abs(statistics.total)), "$", 0, " ")}
                                    </div>
                                </div>
                            </div>

                        </div>
                    }

                    {statistics && statistics.numbers && Array.isArray(statistics.numbers) && statistics.numbers.length > 0 && statistics.numbers.map((item, index) => {

                        if (item.debit - item.credit === 0) {
                            return <div key={`${index}`} />
                        }

                        return (
                            <div className="chart-content-statistics-information-container" key={`${index}`}>
                                <div className="chart-content-statistics-information-container-currency">{item.code}</div>

                                <div className="chart-content-statistics-information">

                                    <div className="chart-content-statistics-information-item currency">
                                        <img src="/images/player/poker-chip.png" alt="Poker Chip" />
                                        <div className="chart-content-statistics-information-box">
                                            <div className="chart-content-statistics-information-text">Средняя ANTE ({item.code})</div>
                                            <div className="chart-content-statistics-information-value">{utils.convertor(parseFloat(item.ante), utils.checkValue(item.symbol, "$"), utils.checkValue(item.isAfter, 0), " ")}</div>
                                        </div>
                                    </div>

                                    <div className="chart-content-statistics-information-item currency">
                                        <img src="/images/player/increase.png" alt="Increase" />
                                        <div className="chart-content-statistics-information-box">
                                            <div className="chart-content-statistics-information-text">DEBIT ({item.code})</div>
                                            <div className="chart-content-statistics-information-value">{utils.convertor(parseFloat(item.debit), utils.checkValue(item.symbol, "$"), utils.checkValue(item.isAfter, 0), " ")}</div>
                                        </div>
                                    </div>

                                    <div className="chart-content-statistics-information-item currency">
                                        <img src="/images/player/decrease.png" alt="Decrease" />
                                        <div className="chart-content-statistics-information-box">
                                            <div className="chart-content-statistics-information-text">CREDIT ({item.code})</div>
                                            <div className="chart-content-statistics-information-value">{utils.convertor(parseFloat(item.credit), utils.checkValue(item.symbol, "$"), utils.checkValue(item.isAfter, 0), " ")}</div>
                                        </div>
                                    </div>

                                    <div className="chart-content-statistics-information-item currency">
                                        <img src="/images/player/calculate.png" alt="Calculate" />
                                        <div className="chart-content-statistics-information-box">
                                            <div className="chart-content-statistics-information-text">TOTAL ({item.code})</div>
                                            <div className={`chart-content-statistics-information-value ${item.debit - item.credit >= 0 ? 'green' : 'red'}`}>
                                                {item.debit - item.credit > 0 && "+ "}
                                                {item.debit - item.credit < 0 && "- "}
                                                {utils.convertor(parseFloat(Math.abs(item.debit - item.credit)), utils.checkValue(item.symbol, "$"), utils.checkValue(item.isAfter, 0), " ")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}


                </div>

            </div>
        )
    }



    render = () => {

        const { status } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="statistics-information-container">
                <div className="statistics-information-container-error">Внимание! Статистика не включает данные тестировщиков! </div>
                {this._information()}
                {this._chart()}
                {this._combinations()}
            </div>
        )
    }

}

export default OEPStatistics