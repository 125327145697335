import axios from "axios"
import { env } from '../constants'

/* GAMES LIST */
export const games = data => {
    const uri = `${env.gamepoint}qmp/game/list`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* GAMES VIEW */
export const game = data => {
    const uri = `${env.gamepoint}qmp/game/get`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* GAME PLAYErS */
export const players = data => {
    const uri = `${env.gamepoint}qmp/game/players`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* CREDIT */
export const credit = data => {
    const uri = `${env.gamepoint}qmp/game/credit`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* VIEW */
export const view = data => {
    const uri = `${env.gamepoint}qmp/game/player-view`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}




/* GAMES LIST */
export const end = data => {
    const uri = `${env.gamepoint}qmp/game/end-game`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}


/* CHANGE PAUSE STATUS */
export const togglePause = data => {
    const uri = `${env.gamepoint}qmp/game/toggle-pause-status`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}
