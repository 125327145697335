import axios from "axios"
import { env } from '../constants'


/* GAMES LIST */
export const games = data => {
    const uri = `${env.gamepoint}qvp/game/list`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}


/* GAMES VIEW */
export const game = data => {
    const uri = `${env.gamepoint}qvp/game/get`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* GAMES LIST */
export const end = data => {
    const uri = `${env.gamepoint}qvp/game/end-game`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* GAME REFUND */
export const credit = data => {
    const uri = `${env.gamepoint}qvp/game/credit`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}


/* GAME CARDS */
export const cards = data => {
    const uri = `${env.gamepoint}qvp/game/cards`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}


/* GAME PLAYErS */
export const players = data => {
    const uri = `${env.gamepoint}qvp/game/players`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* REFUND */
export const refund = data => {
    const uri = `${env.gamepoint}qvp/game/refund`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* REFUND */
export const transactions = data => {
    const uri = `${env.gamepoint}qvp/game/transactions`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}


/* CHANGE PAUSE STATUS */
export const togglePause = data => {
    const uri = `${env.gamepoint}qvp/game/toggle-pause-status`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* END GAME */
export const endGame = data => {
    const uri = `${env.gamepoint}qvp/game/end-game`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}