import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Helpers */
import { utils, withRouter } from '../../helpers'

/* Swal */
import Swal from 'sweetalert2'

/* Components */
import { Input, Layout } from "../../components"

/* REST API */
import { edit, get } from '../../api/Currency'

/* Switch */
import Switch from "react-switch"



/* Page Currency Edit */
class CurrencyEdit extends Component {


    constructor() {
        super()

        this.state = {

            name: "",
            code: "",
            symbol: "",
            isAfter: false,
            waiting: false,
            status: "loading"
        }
    }


    componentDidMount = () => {
        this.load()
    }


    /* Load action */
    load = (reload = false) => {

        const { params } = this.props
        const { id } = params

        if (id) {

            if (reload) {
                this.setState({ status: "loading" })
            }

            get({ id }).then(response => {
                if (response.status === 200) {
                    const data = response.data
                    this.setState({
                        name: utils.checkValue(data.name),
                        code: utils.checkValue(data.code),
                        symbol: utils.checkValue(data.symbol),
                        isAfter: (data.isAfter && parseInt(data.isAfter) > 0) ? true : false,
                        status: "ok"
                    })
                }
                else if (response.status === 401 || response.status === 403) {
                    this.setState({ status: "permission" })
                }
                else if (response.status === 498) {
                    this.setState({ status: "key" })
                }
                else {
                    this.setState({ status: "error" })
                }
            }).catch(() => {
                this.setState({ status: "network" })
            })
        }
        else {
            this.setState({ status: "error" })
        }

    }


    /* Save action */
    save = () => {

        const { name, code, symbol, isAfter } = this.state
        const { params, navigate } = this.props
        const { id } = params

        if (!id) {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            return
        }

        if (name === "") {
            this._name.error("Введите название валюты")
            return
        }

        if (code === "") {
            this._code.error("Введите код валюты")
            return
        }

        if (code.length !== 3) {
            this._code.error("Длина кода должна быть 3 символа")
            return
        }

        if (symbol === "") {
            this._symbol.error("Введите символ валюты")
            return
        }

        this.setState({ waiting: true })

        edit({ id, name, code, symbol, isAfter: isAfter ? "yes" : "no" }).then(response => {
            if (response.status === 200) {
                Swal.fire(utils.notification("Валюта успешно cохранена", "success")).then(() => {
                    navigate(-1)
                })
            }
            else if (response.status === 401 || response.status === 403) {
                Swal.fire(utils.notification("Нет доступа!", "error"))
            }
            else if (response.status === 498) {
                Swal.fire(utils.notification("Неверный ключ!", "error"))
            }
            else {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
        }).finally(() => {
            this.setState({ waiting: false })
        })

    }

    /* Draw button */
    _button = () => {

        const { waiting } = this.state

        if (waiting) {
            return <div className="button"><LoadingOutlined /></div>
        }

        return <div onClick={() => this.save()} className="button">Сохранить</div>
    }

    render = () => {

        const { status, name, code, symbol, isAfter } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Редактировать валюту</h1>
                </div>

                <div className="page-form">
                    <div className="form">
                        <div className="form-box">
                            <Input ref={ref => this._name = ref} label="Название" value={name} onChange={event => this.setState({ name: event.target.value })} />
                            <Input ref={ref => this._code = ref} label="Код" value={code} onChange={event => this.setState({ code: event.target.value })} />
                            <Input ref={ref => this._symbol = ref} label="Символ" value={symbol} onChange={event => this.setState({ symbol: event.target.value })} />

                            <div className="form-switch">
                                <span>Ставить символ перед цифрой</span>
                                <Switch uncheckedIcon={false} checkedIcon={false} onColor="#8bc34a" onChange={value => this.setState({ isAfter: value })} checked={isAfter} />
                            </div>
                        </div>
                    </div>

                    <div className="form-bottom">
                        {this._button()}
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(CurrencyEdit)