import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Helpers */
import { utils, withRouter } from '../../helpers'

/* Swal */
import Swal from 'sweetalert2'

/* Components */
import { Input } from "../../components"

/* REST API */
import { create } from '../../api/Dealer'



/* Page Dealer Create */
class DealerCreate extends Component {


    constructor() {
        super()

        this.state = {
            name: "",
            pincode: "",
            waiting: false
        }
    }

    
    /* Save action */
    save = () => {

        const { name, pincode } = this.state
        const { navigate } = this.props

        if (name === "") {
            this._name.error("Введите имя дилера")
            return
        }

        if (pincode === "") {
            this._pincode.error("Введите PIN/Barcode")
            return
        }

        if (pincode.length !== 6) {
            this._pincode.error("Длина PIN/штрих-кода должна быть 6 символов")
            return
        }

        this.setState({ waiting: true })
        
        create({ name, pincode }).then(response => {
            if (response.status === 200) {
                Swal.fire(utils.notification("Дилер успешно добавлен", "success")).then(() => {
                    navigate(-1)
                })
            }
            else if (response.status === 401 || response.status === 403) {
                Swal.fire(utils.notification("Нет доступа!", "error"))
            }
            else if (response.status === 498) {
                Swal.fire(utils.notification("Неверный ключ!", "error"))
            }
            else {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
        }).finally(() => {
            this.setState({ waiting: false })
        })

    }

    /* Draw button */
    _button = () => {

        const { waiting } = this.state

        if (waiting) {
            return <div className="button"><LoadingOutlined /></div>
        }

        return <div onClick={() => this.save()} className="button">Сохранить</div>
    }

    render = () => {

        const { name, pincode } = this.state

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Добавить дилера</h1>
                </div>

                <div className="page-form">
                    <div className="form">
                        <div className="form-box">
                            <Input ref={ref => this._name = ref} label="Имя дилера" value={name} onChange={event => this.setState({ name: event.target.value })} />
                            <Input ref={ref => this._pincode = ref} label="PIN/Barcode" value={pincode} onChange={event => this.setState({ pincode: event.target.value })} />
                        </div>
                    </div>

                    <div className="form-bottom">
                        {this._button()}
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(DealerCreate)