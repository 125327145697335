import React, { Component } from "react"


/* Component Tag */
class Tag extends Component {

    render = () => {
        const { children, color, right } = this.props
        return <div className={`tag ${color}`} style={{ marginRight: right ? right : 0 }}>{children}</div>
    }

}

export default Tag