import React, { Component } from "react"

/* Components */
import { Layout } from '../../components'

/* REST API */
import { tables } from '../../api/Game'
import { utils } from "../../helpers"


/* Page Monitor */
class Monitor extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            status: "loading"
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load actions */
    load = (reload = false) => {

        if (reload) {
            this.setState({ status: "loading" })
        }

        tables().then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [] })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [] })
            }
            else {
                this.setState({ status: "error", data: [] })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [] })
        })
    }

    render = () => {

        const { status, data } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Мониторы дилера</h1>
                </div>

                <div className="monitors">
                    {data.map((table, index) =>
                        <a href={`${table.monitorURL ? `${table.monitorURL}/?${table.dealerMonitorToken}` : "#"}`} target="_blank" rel="noreferrer" className="monitor" key={`${index}`} style={table.monitorURL ? { opacity: 1, cursor: 'pointer' } : { opacity: 0.5, cursor: 'default', transform: "scale(1)" }}>
                            <div className="monitor-head">
                                <div className="monitor-head-text">Dealer Monitor</div>
                                <div className="monitor-head-dots">
                                    <div className="monitor-head-dot green" />
                                    <div className="monitor-head-dot yellow" />
                                    <div className="monitor-head-dot red" />
                                </div>
                            </div>
                            <div className="monitor-body">
                                <div className="monitor-name">{utils.checkValue(table.name, "-")}</div>
                                <div className="monitor-url">{utils.checkValue(table.monitorURL, "-")}</div>
                                <div className="monitor-token">{utils.checkValue(table.dealerMonitorToken, "-")}</div>
                            </div>
                        </a>
                    )}
                </div>

            </div>
        )
    }
}

export default Monitor