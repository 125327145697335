import axios from "axios"
import { env } from '../constants'


/* List Operator */
export const list = page => {
    const uri = `${env.startpoint}operator/list?page=${page}`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(data => data.response)
}

/* Create Admin */
export const create = data => {
    const uri = `${env.startpoint}operator/create`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Get Admin */
export const get = data => {
    const uri = `${env.startpoint}operator/get`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Edit Admin */
export const edit = data => {
    const uri = `${env.startpoint}operator/edit`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Remove Admin */
export const remove = data => {
    const uri = `${env.startpoint}operator/remove`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Games Admin */
export const games = data => {
    const uri = `${env.startpoint}operator/games`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}


/* Change Game Accept Admin */
export const changeAccept = data => {
    const uri = `${env.startpoint}operator/change-accept`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}
