import React, { Component } from "react"

/* Components */
import { Layout, Tag, Pagination } from '../../components'

/* REST API */
import { list } from '../../api/Notification'

/* Helpers */
import { withRouter, utils } from '../../helpers'

/* Router */
import { Link } from "react-router-dom"



/* Fields */
const PERCENTAGE = 95 / 5



/* Page Notifications */
class Notifications extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            status: "loading",
            count: 0
        }
    }

    componentDidMount = () => {
        this.load()
    }

    componentDidUpdate = (prevProps) => {
        const { params } = this.props
        const { page } = params

        if (prevProps.params.page !== page) {
            this.load()
        }
    }

    /* Get Page */
    getPage = () => {

        const { params } = this.props
        const { page } = params

        if (Number.isInteger(parseInt(page))) {
            return parseInt(page)
        }

        return 1
    }

    /* Load action */
    load = (reload = false) => {

        const page = this.getPage()

        if (reload) {
            this.setState({ status: 'loading' })
        }

        list(page).then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data.list, count: response.data.count })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [], count: 0 })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [], count: 0 })
            }
            else {
                this.setState({ status: "error", data: [], count: 0 })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [], count: 0 })
        })

    }


    /* Draw Time */
    _time = time => {

        const datetime = utils.getTime(time)

        return (
            <div className="page-table-datetime">
                <div className="page-table-time">{datetime.time}</div>
                <div className="page-table-date">{datetime.date}</div>
            </div>
        )
    }

    render = () => {

        const { status, data, count } = this.state
        const { navigate } = this.props
        const page = this.getPage()

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Уведомление</h1>
                </div>

                <div className="page-table">

                    <div className="page-table-header">
                        <div className="page-table-column number">#</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>Стол</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>Уведомление</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>Дата и время</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}></div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}></div>
                    </div>

                    {data.map((item, index) =>
                        <div className="page-table-body" key={`${index}`}>
                            <div className="page-table-column number">{page * (index + 1)}</div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>
                                <b>{item.name}</b>
                                <div style={{ fontSize: 12 }}>{item.slug}</div>
                            </div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>{item.message}</div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>{this._time(item.createdAt)}</div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>
                                {parseInt(item.unread) === 0 ? <Tag color="green">Новый</Tag> : ''}
                            </div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>
                                <Link to={`/table/${item.game}/${item.uid}`} className="notification-button">Перейти</Link>
                            </div>
                        </div>
                    )}

                    <Pagination size={10} page={page} total={count} onPageChange={page => navigate(`/notifications/${page}`)} />

                </div>


            </div>
        )
    }

}

export default withRouter(Notifications)