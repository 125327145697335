import React, { Component } from "react"

/* Components */
import { Input } from "../../components"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { password as change } from '../../api/Profile'

/* Swal */
import Swal from 'sweetalert2'

/* Helpers */
import { utils } from '../../helpers'



/* Page Password */
class Password extends Component {

    constructor() {
        super()

        this.state = {
            password: "",
            newPassword: "",
            repeatPassword: "",
            waiting: false
        }
    }

    /* Save action */
    save = () => {

        const { password, newPassword, repeatPassword } = this.state

        if (password === "") {
            this._password.error("Введите текущий пароль")
            return
        }

        if (password.length < 8) {
            this._password.error("Пароль должен быть больше 8 символов")
            return
        }

        if (newPassword === "") {
            this._newPassword.error("Введите новый пароль")
            return
        }

        if (newPassword.length < 8) {
            this._newPassword.error("Пароль должен быть больше 8 символов")
            return
        }

        if (newPassword !== repeatPassword) {
            this._repeatPassword.error("Пароли не совпадают")
            return
        }

        this._password.error("")
        this._newPassword.error("")
        this._repeatPassword.error("")

        this.setState({ waiting: true })

        change({ password, newPassword }).then(response => {
            if (response.status === 200) {
                this.setState({ waiting: false, password: "", newPassword: "", repeatPassword: "" })
                Swal.fire(utils.notification("Пароль успешно изменен!", "success"))
            }
            else if (response.status === 401) {
                this.setState({ waiting: false })
                this._password.error("Неверный пароль!")
            }
            else if (response.status === 498) {
                this.setState({ waiting: false })
                Swal.fire(utils.notification("Ошибка авторизации!", "error"))
            }
            else {
                this.setState({ waiting: false })
                Swal.fire(utils.notification("Что-то пошло не так! Попробуйте позднее.", "error"))
            }
        }).catch(() => {
            this.setState({ waiting: false })
            Swal.fire(utils.notification("Что-то пошло не так! Попробуйте позднее.", "error"))
        })
    }

    /* Draw button */
    _button = () => {

        const { waiting } = this.state

        if (waiting) {
            return <div className="button"><LoadingOutlined /></div>
        }

        return <div onClick={() => this.save()} className="button">Сохранить</div>
    }

    render = () => {

        const { password, newPassword, repeatPassword } = this.state

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Изменить пароль</h1>
                </div>

                <div className="page-form">

                    <div className="form">
                        <div className="form-box">
                            <Input ref={ref => this._password = ref} label="Текущий пароль" value={password} onChange={event => this.setState({ password: event.target.value })} secure />
                            <div className="form-space" />
                            <Input ref={ref => this._newPassword = ref} label="Новый пароль" value={newPassword} onChange={event => this.setState({ newPassword: event.target.value })} secure />
                            <Input ref={ref => this._repeatPassword = ref} label="Повторите пароль" value={repeatPassword} onChange={event => this.setState({ repeatPassword: event.target.value })} secure />
                        </div>
                    </div>

                    <div className="form-bottom">
                        {this._button()}
                    </div>

                </div>

            </div>
        )
    }

}

export default Password