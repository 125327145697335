import axios from "axios"
import { env } from '../constants'


/* GAMES LIST */
export const games = data => {
    const uri = `${env.gamepoint}rep/game/list`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}


/* GAMES VIEW */
export const game = data => {
    const uri = `${env.gamepoint}rep/game/get`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* GAMES LIST */
export const end = data => {
    const uri = `${env.gamepoint}rep/game/end-game`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* GAME REFUND */
export const credit = data => {
    const uri = `${env.gamepoint}rep/game/credit`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* END GAME */
export const endGame = data => {
    const uri = `${env.gamepoint}rep/game/end-game`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* STATISTICS */
export const statistics = data => {
    const uri = `${env.gamepoint}rep/statistic/get`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* STATISTICS */
export const periodStatistics = data => {
    const uri = `${env.gamepoint}rep/statistic/period`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}
