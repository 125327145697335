import React, { Component } from "react"

/* Router */
import { Link } from "react-router-dom"

/* Cookie */
import Cookies from "js-cookie"

/* Helpers */
import { withRouter } from '../helpers'

/* Constants */
import { sound } from '../constants'

/* REST API */
import { get } from '../api/Notification'


/* Fields */
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)



/* Component Header */
class Header extends Component {

    constructor() {
        super()

        this.state = {
            visible: false,
            audioLoading: true,
            unread: 0,
            warning: false
        }

        this._audio = null
    }

    componentDidMount = () => {
        this.socketEvents()
        this.load()
        document.addEventListener('click', this.handleClickOutside, true)
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClickOutside, true)
        this.removeSocketEvents()
    }

    /* Click Outside Handler */
    handleClickOutside = event => {

        if (this._menu && !this._menu.contains(event.target)) {
            this.setState({ visible: false })
        }

        if (isIOS) {

            const { audioLoading } = this.state

            if (audioLoading) {

                sound.soundEffects.forEach(soundEffect => {
                    soundEffect.audio.play()
                    soundEffect.audio.pause()
                })

                this.setState({ audioLoading: false })
            }
        }
    }

    /* Load Notification Count */
    load = () => {
        get().then(response => {
            if (response.status === 200) {
                if (response.data.unread && parseInt(response.data.unread) > 0) {
                    this.setState({ unread: parseInt(response.data.unread) })
                }
            }
        })
    }
    

    /* Handle Socket events */
    socketEvents = () => {

        const { socket } = this.props

        socket.on("pause", () => {
            const { more } = this.state
            this._audio = sound.play("warning")
            this.setState({ warning: true, more: more + 1 })
        })

    }

    /* Go Nofitication */
    goNotification = () => {

        const { navigate } = this.props

        if (this._audio) {
            this._audio.stop()
        }

        this.setState({ warning: false, unread: 0 })

        navigate("/notifications")
    }

    /* Remove Socket events */
    removeSocketEvents = () => {
        const { socket } = this.props
        socket.off("pause")
    }

    /* Logout action */
    logout = () => {
        const { setToken, navigate } = this.props
        localStorage.removeItem("token")
        Cookies.remove("refresh")
        setToken(null)
        navigate('/')
    }


    /* Draw Notification */
    _notification = () => {

        const { warning, unread } = this.state

        return (
            <div onClick={() => this.goNotification()} className={`header-notification ${warning ? 'header-notification-warning' : ''}`}>
                <img src="/images/bell.png" alt="Bell" />
                {parseInt(unread) > 0 ? <div className="header-notification-dot">{unread}</div> : null}
            </div>
        )
    }


    render = () => {

        const { visible } = this.state
        const json = localStorage.getItem("user")
        const user = json ? JSON.parse(json) : null

        return (
            <div style={{ overflow: 'hidden' }}>

                <div className="header">

                    {this._notification()}

                    {user !== null && user !== undefined &&
                        <div onClick={() => this.setState({ visible: !visible })} className="header-user">

                            <img src="/images/profile.png" alt="Profile" />
                            <img src="/images/ava-white.png" alt="Profile" className="mobile-image" />

                            <div className="header-user-text">
                                <div className="header-user-name">{user.name}</div>
                                <div className="header-user-role">{user.role}</div>
                            </div>

                            <div className="header-more">
                                <img src="/images/more.png" alt="More" />
                            </div>

                        </div>
                    }

                </div>

                <div ref={ref => this._menu = ref} className={`header-dropdown-menu ${visible ? 'active' : ''}`}>

                    <Link onClick={() => this.setState({ visible: false })} to="/profile" className="header-dropdown-menu-item">
                        <img src="/images/person.png" alt="Avatar" />
                        <span>Профиль</span>
                    </Link>

                    <div className="header-dropdown-menu-divider" />

                    <Link onClick={() => this.setState({ visible: false })} to="/change-password" className="header-dropdown-menu-item">
                        <img src="/images/password.png" alt="Password" />
                        <span>Изменить пароль</span>
                    </Link>

                    <div className="header-dropdown-menu-divider" />

                    <Link onClick={() => this.setState({ visible: false })} to="/history" className="header-dropdown-menu-item">
                        <img src="/images/clock.png" alt="Clock" />
                        <span>История входа</span>
                    </Link>

                    <div className="header-dropdown-menu-divider" />

                    <div onClick={() => this.logout()} className="header-dropdown-menu-item">
                        <img src="/images/logout.png" alt="Logout" />
                        <span>Выйти</span>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(Header)