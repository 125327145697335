import React, { Component } from "react"



/* Component Avatar */
class Avatar extends Component {

    constructor() {
        super()

        this.state = {
            isLoaded: false
        }
    }

    render = () => {

        const { uri, size } = this.props
        const { isLoaded } = this.state
        const style = size ? { width: size, height: size, borderRadius: size / 2 } : { width: 40, height: 40, borderRadius: 20 }

        /* Draw Default Picture */
        if (!uri) {
            return (
                <div className="avatar default" style={style}>
                    <img src="/images/ava-black.png" alt="Avatar" />
                </div>
            )
        }

        return (
            <div className="avatar" style={style}>
                <img style={{ opacity: isLoaded ? 1 : 0 }} src={uri} alt="Avatar" onLoad={() => this.setState({ isLoaded: true })} />
                <img style={{ opacity: isLoaded ? 0 : 0.5 }} src="/images/ava-black.png" className="default" alt="Avatar" />
            </div>
        )
    }

}

export default Avatar
