import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'



/* Component Reload */
class Reload extends Component {

    render = () => {

        const { status, reload } = this.props

        if (status === "loading") {
            return (
                <div className="reload loading">
                    <LoadingOutlined />
                </div>
            )
        }

        return (
            <div className="reload" onClick={() => reload()}>
                <img src="/images/reload.png" alt="Reload" />
            </div>
        )
    }

}

export default Reload