const data = [
    { name: 'dashboard', text: 'Dashboard', link: '/', image: <img src="/images/home.png" alt="Home" /> },
    { name: 'game', text: 'Игры', link: '/game', image: <img src="/images/spade.png" alt="Spade" /> },
    { name: 'player', text: 'Игроки', link: '/player', image: <img src="/images/user.png" alt="User" /> },
    { name: 'message', text: 'Сообщение', link: '/message', image: <img src="/images/chat.png" alt="Chat" /> },
    { name: 'space' },
    { name: 'currency', text: 'Валюты', link: '/currency', image: <img src="/images/dollar.png" alt="Dollar" /> },
    { name: 'card', text: 'Карты', link: '/card', image: <img src="/images/cards.png" alt="Cards" /> },
    { name: 'dealer', text: 'Дилеры', link: '/dealer', image: <img src="/images/croupier.png" alt="Croupier" /> },
    { name: 'stream', text: 'Мониторы дилера', link: '/monitors', image: <img src="/images/monitor.png" alt="Monitor" /> },
    { name: 'stream', text: 'Стримы', link: '/stream', image: <img src="/images/video-camera.png" alt="Video camera" /> },
    { name: 'space' },
    { name: 'user', text: 'Пользователи', link: '/user', image: <img src="/images/avatar.png" alt="Avatar" /> },
    { name: 'user', text: 'Операторы', link: '/operator', image: <img src="/images/avatar.png" alt="Avatar" /> },
    { name: 'role', text: 'Роли', link: '/role', image: <img src="/images/permission.png" alt="Permission" /> },
]

export {  data}