import React, { Component } from "react"

/* Components */
import { Input } from "../../components"

/* REST API */
import { password as changePassword } from '../../api/Admin'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Helpers */
import { utils, withRouter } from "../../helpers"

/* Swal */
import Swal from 'sweetalert2'



/* Page Admin Change Password */
class AdminChangePassword extends Component {

    constructor() {
        super()

        this.state = {
            password: "",
            repeatPassword: "",
            waiting: false
        }
    }

    /* Save action */
    save = () => {

        const { password, repeatPassword } = this.state
        const { navigate, params } = this.props
        const { id } = params

        if (!id) {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            return
        }

        if (password === "" || password === null || password === undefined) {
            this._password.error("Введите пароль")
            return
        }

        if (password.length < 8) {
            this._password.error("Пароль должен быть больше 8 символов")
            return
        }

        if (password !== repeatPassword) {
            this._repeatPassword.error("Пароли не совпадают")
            return
        }

        this.setState({ waiting: true })

        changePassword({ id, password }).then(response => {
            if (response.status === 200) {
                Swal.fire(utils.notification("Пароль успешно сохранен", "success")).then(() => {
                    navigate(-1)
                })
            }
            else if (response.status === 401 || response.status === 403) {
                Swal.fire(utils.notification("Нет доступа!", "error"))
            }
            else if (response.status === 498) {
                Swal.fire(utils.notification("Неверный ключ!", "error"))
            }
            else {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
        }).finally(() => {
            this.setState({ waiting: false })
        })


    }

    /* Generate Password */
    generatePassword = () => {
        const password = utils.generatePassword()
        this.setState({ password, repeatPassword: password })
    }

    /* Draw button */
    _button = () => {

        const { waiting } = this.state

        if (waiting) {
            return <div className="button"><LoadingOutlined /></div>
        }

        return <div onClick={() => this.save()} className="button">Сохранить</div>
    }

    render = () => {

        const { password, repeatPassword } = this.state

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Изменить пароль</h1>
                </div>

                <div className="page-form">

                    <div className="form">
                        <div className="form-box">
                            <Input ref={ref => this._password = ref} label="Новый пароль" value={password} onChange={event => this.setState({ password: event.target.value })} secure />
                            <Input ref={ref => this._repeatPassword = ref} label="Повторите новый пароль" value={repeatPassword} onChange={event => this.setState({ repeatPassword: event.target.value })} secure />
                            <div onClick={() => this.generatePassword()} className="form-generate-password">Сгенерировать пароль</div>
                        </div>
                    </div>

                    <div className="form-bottom">
                        {this._button()}
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(AdminChangePassword)