import React, { Component } from 'react'

/* Components */
import { Tag, Process, Transaction, Cards, Avatar, Notes } from '../../components'

/* Widgets */
import { Layout } from '../Table'

/* REST API */
import { game, credit, endGame } from '../../api/OEP'

/* Helpers */
import { utils } from '../../helpers'

/* Sweet alert */
import Swal from "sweetalert2"

/* Modal */
import Modal from 'react-modal'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Moment */
import moment from 'moment-timezone'
import 'moment/locale/ru'

/* Moment Settings */
moment.locale("ru")

/* Widget OEP Game Information */
class OEPGameInformation extends Component {

    constructor() {
        super()

        this.state = {
            table: null,
            data: null,
            transactions: [],
            gameProcess: [],
            results: [],
            cards: [],
            status: 'loading',

            visible: false,
            type: "",
            amount: "",
            waiting: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Game Information */
    load = (reload = false) => {

        /* Fields */
        const { uid, gameID } = this.props

        /* Validate */
        if (!uid || !gameID) {
            this.setState({ status: 'error', data: null })
            return
        }

        /* Reload */
        if (reload) {
            this.setState({ status: "loading" })
        }

        /* Send request to REST API */
        game({ uid, gameID }).then(response => {
            if (response.status === 200) {
                this.setState({
                    status: "ok",
                    data: response.data.game,
                    table: response.data.table,
                    transactions: response.data.transactions,
                    gameProcess: response.data.gameProcess,
                    cards: response.data.cards,
                    results: response.data.results
                })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: null, table: null })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: null, table: null })
            }
            else {
                this.setState({ status: "error", data: null, table: null })
            }
        }).catch(() => {
            this.setState({ status: "network", data: null, table: null })
        })

    }

    /* Get Result */
    getResult = (type, box) => {

        const { results } = this.state

        if (results && Array.isArray(results) && results.length > 0) {
            const index = results.findIndex(e => e.type === type && parseInt(e.box) === parseInt(box))
            if (index > -1) {
                return results[index]
            }
        }

        return null
    }


    /* Get Cards */
    getCards = (type, box) => {

        /* Fields */
        const { cards } = this.state

        let list = []

        if (cards && Array.isArray(cards) && cards.length > 0) {
            cards.forEach(card => {
                if (card.type === type && parseInt(card.box) === parseInt(box)) {
                    list.push(card)
                }
            })
        }

        return list
    }


    /* BALANCE CALCULATION */
    calculateBalance = () => {

        const { data, transactions } = this.state

        if (data && data.startBalance) {

            let endBalance = parseFloat(data.startBalance)
            let debit = 0
            let credit = 0
            let total = 0

            if (transactions && Array.isArray(transactions) && transactions.length > 0) {
                transactions.forEach(transaction => {
                    if (parseInt(transaction.status) === 1) {

                        if (transaction.type === 'credit') {
                            endBalance = endBalance + parseFloat(transaction.total)
                            credit = credit + parseFloat(transaction.total)
                            total = total - parseFloat(transaction.total)
                        }

                        if (transaction.type === 'debit') {
                            endBalance = endBalance - parseFloat(transaction.total)
                            debit = debit + parseFloat(transaction.total)
                            total = total + parseFloat(transaction.total)
                        }

                    }
                })
            }

            return {
                debit: utils.convertor(parseFloat(debit), data.symbol),
                credit: utils.convertor(parseFloat(credit), data.symbol),
                total: utils.convertor(parseFloat(total), data.symbol),
                startBalance: utils.convertor(parseFloat(data.startBalance), data.symbol),
                endBalance: parseInt(data.status) === 1 ? utils.convertor(endBalance, data.symbol) : "-",
            }

        }

        return { startBalance: "-", endBalance: "-", total: "-", debit: "-", credit: "-" }
    }



    /* End Game */
    end = (refund = false) => {

        const { data, transactions } = this.state
        const { socket, uid } = this.props

        if (parseInt(data.status) === 1) {
            return
        }

        let currency = "USD"
        if (transactions && Array.isArray(transactions) && transactions.length > 0) {
            transactions.forEach(transaction => {
                currency = transaction.currency
            })
        }

        Swal.fire({
            title: 'Вы действительно хотите завершить игру?',
            text: "Невозможно восстановить после изменений",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2196f3',
            reverseButtons: true,
            confirmButtonText: 'Да, завершить',
            cancelButtonText: 'Отмена'
        }).then(async result => {
            if (result.isConfirmed) {

                /* With Refund */
                if (refund) {

                    let debit = 0

                    if (transactions && Array.isArray(transactions) && transactions.length > 0) {
                        transactions.forEach(transaction => {
                            if (parseInt(transaction.status) === 1 && transaction.type === 'debit') {
                                debit = debit + parseFloat(transaction.total)
                            }
                        })
                    }

                    const refund = data.refund ? parseFloat(data.refund) : 0
                    const total = debit - refund

                    if (total > 0) {
                        credit({ uid, type: "refund", refund: total, currency, playerID: data.player, id: data.id }).then(creditRes => {
                            if (creditRes.status === 200) {

                                /* End Game */
                                endGame({ uid, gameID: data.id }).then(response => {
                                    if (response.status === 200) {
                                        if (response.data.updated === "yes") {
                                            socket.emit("end", { playerID: data.player, gameID: data.id })
                                            socket.emit("adminNotification", { type: "refund", title: "Return of funds", total: refund, currency, playerID: data.player, gameID: data.id })
                                            Swal.fire(utils.notification("Игра завершена успешно!", "success", 3000))
                                            this.load(true)
                                        }
                                        else {
                                            Swal.fire(utils.notification("Игра уже завершена!", "error", 3000))
                                        }
                                    }
                                    else {
                                        Swal.fire(utils.notification("Что-то пошло не так! Попробуйте позднее!", "error"))
                                    }
                                }).catch(() => {
                                    Swal.fire(utils.notification("Что-то пошло не так! Попробуйте позднее!", "error"))
                                })

                            }
                            else {
                                this.load()
                                Swal.fire(utils.notification("Не удалось завершить транзакцию!", "error", 4000))
                            }
                        }).catch(() => {
                            this.load()
                            Swal.fire(utils.notification("Не удалось завершить транзакцию!", "error", 4000))
                        })
                    }
                    else {
                        Swal.fire(utils.notification("Не удалось завершить транзакцию!", "error", 4000))
                    }

                }
                else {
                    /* End Game */
                    endGame({ uid, gameID: data.id }).then(response => {
                        if (response.status === 200) {
                            if (response.data.updated === "yes") {
                                socket.emit("end", { playerID: data.player, gameID: data.id })
                                Swal.fire(utils.notification("Игра завершена успешно!", "success", 3000))
                                this.load(true)
                            }
                            else {
                                Swal.fire(utils.notification("Игра уже завершена!", "error", 3000))
                            }
                        }
                        else {
                            Swal.fire(utils.notification("Что-то пошло не так! Попробуйте позднее!", "error"))
                        }
                    }).catch(() => {
                        Swal.fire(utils.notification("Что-то пошло не так! Попробуйте позднее!", "error"))
                    })
                }
            }
        })
    }

    /* Refund & Pay actions */
    send = type => {

        const { amount, data, transactions } = this.state
        const { uid, socket } = this.props

        if (amount === null || amount === undefined || amount === "" || parseFloat(amount) === 0) {
            Swal.fire(utils.notification("Введите сумму", "error"))
            return
        }

        let currency = "USD"
        if (transactions && Array.isArray(transactions) && transactions.length > 0) {
            transactions.forEach(transaction => {
                currency = transaction.currency
            })
        }

        Swal.fire({
            title: 'Вы действительно хотите выполнить операцию?',
            text: "Невозможно восстановить после изменений",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2196f3',
            reverseButtons: true,
            confirmButtonText: 'Да, выполнить',
            cancelButtonText: 'Отмена'
        }).then(result => {
            if (result.isConfirmed) {

                this.setState({ waiting: true })

                /* SEND TO REST API */
                credit({ uid, type, refund: amount, currency, playerID: data.player, id: data.id }).then(response => {
                    if (response.status === 200) {

                        if (type === "refund") {
                            socket.emit("adminNotification", { type: "refund", title: "Return of funds", total: amount, currency, playerID: data.player, gameID: data.id })
                        }
                        else {
                            socket.emit("adminNotification", { type: "payment", title: `Replenishment of the amount`, total: amount, currency, playerID: data.player, gameID: data.id })
                        }

                        Swal.fire(utils.notification(type === "refund" ? "Возврат успешно завершен" : "Оплата успешно завершена", "success"))

                    }
                    else {
                        Swal.fire(utils.notification("Что-то пошло не так! Попробуйте позднее!", "error"))
                    }
                }).catch(() => {
                    Swal.fire(utils.notification("Что-то пошло не так! Попробуйте позднее!", "error"))
                }).finally(() => {
                    this.load()
                    this.setState({ waiting: false, type: "", visible: false, amount: "" })
                })
            }
        })

    }

    /* Draw Payment Modal */
    _modal = () => {

        /* Fields */
        const { visible, type, amount, data, gameProcess, waiting } = this.state

        /* Game Fields */
        const balance = this.calculateBalance()
        const index = gameProcess.findIndex(p => p.type = "ante")

        return (
            <Modal
                isOpen={visible}
                onRequestClose={() => this.setState({ visible: false, type: "", amount: "" })}
                className={`refund--modal`}
                overlayClassName="refund--overlay"
                closeTimeoutMS={200}
            >
                <div className="refund--modal-header">
                    <h2>{type === "refund" ? "Возврат ставки" : "Оплата суммы"}</h2>
                    <div onClick={() => this.setState({ visible: false, type: "", amount: "" })} className="refund--modal-close">
                        <img src="/images/close.png" alt="Close" />
                    </div>
                </div>

                <div className="refund--modal-content">

                    {type === "refund" &&
                        <div className="refund--modal-information">
                            <div className="refund--modal-information-item">
                                <div className="refund--modal-information-text">Общая ставка</div>
                                <div className="refund--modal-information-name">{balance.debit}</div>
                            </div>
                            <div className="refund--modal-information-item">
                                <div className="refund--modal-information-text">Возвращено</div>
                                <div className="refund--modal-information-name">{utils.convertor(data.refund ? parseFloat(data.refund) : 0, data.symbol)}</div>
                            </div>
                        </div>
                    }

                    {type === "payment" &&
                        <div className="refund--modal-information">
                            <div className="refund--modal-information-item">
                                <div className="refund--modal-information-text">ANTE</div>
                                <div className="refund--modal-information-name">{index > -1 ? utils.convertor(gameProcess[index].total, data.symbol) : "-"}</div>
                            </div>
                            <div className="refund--modal-information-item">
                                <div className="refund--modal-information-text">Оплачено</div>
                                <div className="refund--modal-information-name">{utils.convertor(data.paid ? parseFloat(data.paid) : 0, data.symbol)}</div>
                            </div>
                        </div>
                    }

                    <input placeholder="Введите сумму" type="text" value={amount} onChange={event => this.setState({ amount: event.target.value.replace(/\D/, '') })} />

                    {waiting
                        ? <div className="refund--modal-button disabled"><LoadingOutlined /></div>
                        : <div className="refund--modal-button" onClick={() => this.send(type)}>Оплатить</div>
                    }

                </div>

            </Modal>
        )
    }


    /* Box Count */
    getBoxCount = () => {

        const { gameProcess } = this.state

        let count = 0

        if (gameProcess && Array.isArray(gameProcess) && gameProcess.length > 0) {
            gameProcess.forEach(process => {
                if (process.type === "ante" && parseFloat(process.total) > 0) {
                    count = count + 1
                }
            })
        }

        return count
    }


    render = () => {

        /* Fields */
        const { status, table, data, transactions, gameProcess } = this.state

        /* Preloaders */
        if (status !== "ok") {
            return (
                <div className='oep--game-inner'>
                    <Layout status={status} reload={() => this.load(true)} />
                </div>
            )
        }

        /* Game Fields */
        const balance = this.calculateBalance()
        const datetime = utils.getTime(data.createdAt)
        const boxCount = this.getBoxCount()

        return (
            <div className="oep--game-inner">
                <div className="oep--game-information">
                    <div className="oep--game-information-row">

                        {/* Game Information */}
                        <div className="oep--game-information-element">

                            {/* Name */}
                            <div className="oep--game-information-item">
                                <img src="/images/game/spades.png" alt="Spades" />
                                <div className="oep--game-information-item-box">
                                    <div className="oep--game-information-item-title">Игра</div>
                                    <div className="oep--game-information-item-text">{table.name}</div>
                                </div>
                            </div>

                            {/* Number */}
                            <div className="oep--game-information-item">
                                <img src="/images/game/shape.png" alt="Shape" />
                                <div className="oep--game-information-item-box">
                                    <div className="oep--game-information-item-title">Номер игры</div>
                                    <div className="oep--game-information-item-text">{data.number}</div>
                                </div>
                            </div>

                            {/* Date */}
                            <div className="oep--game-information-item">
                                <img src="/images/game/clock.png" alt="Clock" />
                                <div className="oep--game-information-item-box">
                                    <div className="oep--game-information-item-title">Дата</div>
                                    <div className="oep--game-information-item-text">{datetime.time}, <span>{datetime.date}</span></div>
                                </div>
                            </div>

                            {/* Player */}
                            <div className="oep--game-information-item">
                                <Avatar uri="/images/player/gamer.png" size={24} />
                                <div className="oep--game-information-item-box">
                                    <div className="oep--game-information-item-title">Игрок {data.player}</div>
                                    <div className="oep--game-information-item-text">{data.firstName}</div>
                                </div>
                            </div>

                            {/* Game Status */}
                            <div className="oep--game-information-item">
                                <img src="/images/game/flag.png" alt="Flag" />
                                <div className="oep--game-information-item-box">
                                    <div className="oep--game-information-item-title">Статус игры</div>
                                    {parseInt(data.status) === 1 ? <Tag color="blue">Завершен</Tag> : <Tag color="purple">Не завершен</Tag>}
                                </div>
                            </div>

                        </div>


                        {/* Player Information */}
                        <div className='oep--game-information-element'>

                            {/* Debit */}
                            <div className="oep--game-information-item">
                                <img src="/images/game/increase.png" alt="Increase" />
                                <div className="oep--game-information-item-box">
                                    <div className="oep--game-information-item-title">Debit</div>
                                    <div className="oep--game-information-item-text">{balance.debit}</div>
                                </div>
                            </div>

                            {/* Debit */}
                            <div className="oep--game-information-item">
                                <img src="/images/game/decrease.png" alt="Decrease" />
                                <div className="oep--game-information-item-box">
                                    <div className="oep--game-information-item-title">Credit</div>
                                    <div className="oep--game-information-item-text">{balance.credit}</div>
                                </div>
                            </div>

                            {/* Calculate */}
                            <div className="oep--game-information-item">
                                <img src="/images/game/calculate.png" alt="Calculate" />
                                <div className="oep--game-information-item-box">
                                    <div className="oep--game-information-item-title">Итого</div>
                                    <div className="oep--game-information-item-text">{balance.total}</div>
                                </div>
                            </div>

                            {/* Start Balance */}
                            <div className="oep--game-information-item">
                                <img src="/images/game/dollar-coin.png" alt="Dollar" />
                                <div className="oep--game-information-item-box">
                                    <div className="oep--game-information-item-title">Баланс до игры</div>
                                    <div className="oep--game-information-item-text">{balance.startBalance}</div>
                                </div>
                            </div>

                            {/* End Balance */}
                            <div className="oep--game-information-item">
                                <img src="/images/game/dollar-coin.png" alt="Dollar" />
                                <div className="oep--game-information-item-box">
                                    <div className="oep--game-information-item-title">Баланс после игры</div>
                                    <div className="oep--game-information-item-text">{balance.endBalance}</div>
                                </div>
                            </div>

                        </div>


                        <div className='oep--game-information-big-element'>

                            <div className="oep--game-information-text">Game actions</div>

                            <div className="oep--game-buttons">
                                <div onClick={() => this.end()} className={`oep--game-button ${parseInt(data.status) === 1 ? 'disabled' : ''}`} >Завершить игру</div>
                                <div onClick={() => this.end(true)} className={`oep--game-button gray ${parseInt(data.status) === 1 ? 'disabled' : ''}`} >Завершить и вернуть ставку</div>
                            </div>

                            <div className="oep--game-information-text">Payment actions</div>

                            <div className="oep--game-buttons">
                                <div onClick={() => this.setState({ visible: true, type: "refund" })} className="oep--game-button purple">Возврат ставки</div>
                                <div onClick={() => this.setState({ visible: true, type: "payment" })} className="oep--game-button pink">Оплата суммы</div>
                            </div>

                        </div>

                    </div>

                    {/* Processes */}
                    <Process count={boxCount} symbol={data.symbol} data={gameProcess} />

                    {/* Transactions */}
                    <Transaction symbol={data.symbol} data={transactions} />

                    {/* Cards */}
                    <div className="cards--container">
                        <Cards title="Dealer Cards" cards={this.getCards("dealer", 0)} result={this.getResult("dealer", 0)} />
                        <Cards title="BOX 1" cards={this.getCards("player", 1)} result={this.getResult("player", 1)} />
                        <Cards title="BOX 2" cards={this.getCards("player", 2)} result={this.getResult("player", 2)} />
                    </div>

                    {/* Payment Modal */}
                    {this._modal()}

                </div>

                <Notes uid={table.uid} gameID={data.id} />

            </div>
        )
    }

}

export default OEPGameInformation