import axios from "axios"
import { env } from '../constants'


/* List Notes */
export const notes = data => {
    const uri = `${env.startpoint}note/list`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Create Notes */
export const create = data => {
    const uri = `${env.startpoint}note/create`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}