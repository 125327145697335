import axios from "axios"
import { env } from '../constants'


/* List Card */
export const list = page => {
    const uri = `${env.startpoint}card/list?page=${page}`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(data => data.response)
}

/* Get Card */
export const get = data => {
    const uri = `${env.startpoint}card/get`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}


/* Edit Card */
export const edit = data => {
    const uri = `${env.startpoint}card/edit`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}