import React, { Component } from "react"

/* Components */
import { Layout } from '../../components'

/* Helpers */
import { withRouter } from '../../helpers'

/* REST API */
import { get } from '../../api/Table'

/* SOCKET.IO */
import { io } from 'socket.io-client'

/* Icons */
import { LoadingOutlined } from "@ant-design/icons"

/* Widgets */
import { OEPGames, OEPGameInformation, OEPStatistics } from '../../widgets/OEP'
import { Settings, Currency } from '../../widgets/Table'

/* Modal */
import Modal from 'react-modal'



/* GLOBAL FIELDS */
const menu = [
    { key: 'statistics', text: 'Статистика', image: <img src="/images/increase.png" alt="Increase" /> },
    { key: 'currencies', text: 'Валюты', image: <img src="/images/dollar.png" alt="Dollar" /> },
    { key: 'settings', text: 'Настройки', image: <img src="/images/setting.png" alt="Setting" /> },
]


/* Page OEPGame */
class OEPGame extends Component {

    constructor() {
        super()

        this.state = {
            status: "loading",
            connection: "loading",
            data: null,
            page: '',
            gameID: 0,
            number: ''
        }

        this.socket = null
        this.timing = null
    }


    componentDidMount = () => {

        /* Start Load Data About Table */
        this.load()

        /* Start Listen Socket Events */
        this.socketEvents()

    }


    componentWillUnmount = () => {

        /* Remove Timing */
        if (this.timing) {
            clearTimeout(this.timing)
            this.timing = null
        }

        /* Remove Socket */
        if (this.socket) {
            this.socket.off('connect')
            this.socket.off('disconnect')
            this.socket.disconnect()
            this.socket.close()
            this.socket = null
        }

    }


    /* Load Table Data */
    load = (reload = false) => {

        /* Fields */
        const { uid } = this.props.params

        /* Check Data */
        if (uid) {

            /* Refresh the page if necessary */
            if (reload) {
                this.setState({ status: 'loading' })
            }

            /* START REST API */
            get({ uid }).then(response => {
                if (response.status === 200) {
                    const data = response.data
                    this.setState({ status: "ok", data })
                    this.connect(data)
                }
                else if (response.status === 401 || response.status === 403) {
                    this.setState({ status: "permission", data: null })
                }
                else if (response.status === 498) {
                    this.setState({ status: "key", data: null })
                }
                else {
                    this.setState({ status: "error", data: null })
                }
            }).catch(() => {
                this.setState({ status: "network", data: null })
            })
        }
        else {
            this.setState({ status: "error", data: null })
        }

    }

    /* Connect Socket */
    connect = data => {
        try {
            const token = localStorage.getItem("token")
            this.socket = io(data.backURL, { auth: { token: `${data.adminToken}`, admin: token } })
        }
        catch {
            this.setState({ connection: "disconnected" })
        }
    }

    /* Socket Events */
    socketEvents = () => {

        if (this.socket) {

            if (this.timing) {
                clearTimeout(this.timing)
                this.timing = null
            }

            if (this.socket.connected) {
                this.setState({ connection: "connected" })
            }

            this.socket.on("connect", () => {
                this.setState({ connection: "connected" })
            })

            this.socket.on("connect_error", () => {
                this.setState({ connection: "disconnected" })
            })

            this.socket.on("disconnect", () => {
                this.setState({ connection: "disconnected" })
            })

        }
        else {
            this.timing = setTimeout(() => {
                clearTimeout(this.timing)
                this.timing = null
                this.socketEvents()
            }, 2000)
        }

    }


    /* Get Menu Data */
    getData = page => {

        /* Fields */
        const { uid } = this.props.params

        if (page === 'game') {

            const { number, gameID } = this.state

            return {
                title: `Игра #${number}`,
                content: <OEPGameInformation closeWithUpdate={() => this.closeWithUpdate()} gameID={gameID} uid={uid} socket={this.socket} />
            }
        }

        const index = menu.findIndex(e => e.key === page)


        if (index > -1) {

            let content = null

            if (page === 'currencies') {
                content = <Currency uid={uid} />
            }

            if (page === 'settings') {
                content = <Settings uid={uid} />
            }

            if (page === 'statistics') {
                content = <OEPStatistics uid={uid} />
            }

            return {
                title: menu[index].text,
                content
            }
        }

        return {
            title: '',
            content: null
        }

    }


    /* Draw Connection State */
    _connection = () => {

        const { connection } = this.state

        if (connection === "connected") {
            return (
                <div className="oep-game-header-connection-status connected">
                    <img src="/images/correct.png" alt="Correct" />
                    Соединение установлено
                </div>
            )
        }

        if (connection === "disconnected") {
            return (
                <div className="oep-game-header-connection-status disconnected">
                    <img src="/images/cross.png" alt="Cross" />
                    Соединение прервано
                </div>
            )
        }

        return (
            <div className="oep-game-header-connection-status">
                <LoadingOutlined />
                Соединение ...
            </div>
        )
    }


    /* Draw Menu */
    _menu = () => {
        return (
            <div className="oep--game-menu">

                {menu.map((item, index) =>
                    <div onClick={() => this.setState({ page: item.key })} className="oep--game-menu-item" key={`${index}`}>
                        {item.image}
                        <span>{item.text}</span>
                    </div>
                )}

                <a target="_blank" href="https://zm.makao.in/" rel="noreferrer" className="oep--game-menu-item video">
                    <img src="/images/video-folder.png" alt="VideoFolder" />
                    <span>Видео архив</span>
                </a>

            </div>
        )
    }

    /* Open Game Inner Action */
    openGame = (id, number) => {
        this.setState({ page: 'game', gameID: id, number })
    }

    /* Close Modal Action */
    close = () => {
        this.setState({ page: '', gameID: 0, number: '' })
    }

    /* Close with Update */
    closeWithUpdate = () => {
        if (this._games) {
            this._games.reloadGames()
        }
        this.setState({ page: '', gameID: 0, number: '' })
    }

    /* Draw Modal */
    _modal = () => {

        const { page } = this.state

        const data = this.getData(page)

        return (
            <Modal
                isOpen={page !== ''}
                onRequestClose={() => this.close()}
                className={`oep--modal ${page === 'game' ? 'oep--modal-game' : page === 'statistics' ? 'oep--modal-statistics' : ''}`}
                overlayClassName={`oep--overlay ${page === 'statistics' ? 'oep--overlay-statistics' : ''}`}
                closeTimeoutMS={200}
            >

                <div className="oep--modal-header">
                    <h2>{data.title}</h2>
                    <div onClick={() => this.close()} className="oep--modal-close">
                        <img src="/images/close.png" alt="Close" />
                    </div>
                </div>

                {data.content}

            </Modal>
        )
    }


    render = () => {

        const { status, data } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page">

                <div className="oep--game-header">
                    <h1>{data.name}</h1>
                    <div className="oep-game-header-connection">
                        <p>{data.slug}</p>
                        {this._connection()}
                    </div>
                </div>

                {this._menu()}

                <OEPGames ref={ref => this._games = ref} uid={data.uid} openGame={(id, number) => this.openGame(id, number)} />

                {this._modal()}

            </div>
        )
    }

}

export default withRouter(OEPGame)