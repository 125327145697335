import React, { Component } from "react"

/* Components */
import { Layout } from '../../components'

/* REST API */
import { tables } from '../../api/Game'

/* Router */
import { Link } from "react-router-dom"

/* Constants */
import { games } from '../../constants'



/* Page Games */
class Games extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            status: "loading"
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load actions */
    load = (reload = false) => {

        if (reload) {
            this.setState({ status: "loading" })
        }

        tables().then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [] })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [] })
            }
            else {
                this.setState({ status: "error", data: [] })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [] })
        })
    }


    /* Get Filtered List */
    filtered = data => {

        let tables = []

        if (Array.isArray(data) && data.length > 0) {
            games.data.forEach(game => {

                let list = []

                data.forEach(table => {
                    if (table.game === game.key) {
                        list.push(table)
                    }
                })

                tables.push({ name: game.name, type: game.type, games: list })
            })
        }

        return tables
    }


    render = () => {

        const { status, data } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        const tables = this.filtered(data)

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Игры</h1>
                    <Link to="/game/create" className="button">
                        <img src="/images/plus.png" alt="Plus" />
                        <span>Добавить</span>
                    </Link>
                </div>

                {tables.map((item, index) => {

                    if (item.games.length === 0) {
                        return <div key={`${index}`} />
                    }

                    return (
                        <div className="games-box" key={`${index}`}>
                            <div className="games-title">{item.name}</div>
                            <div className="games">
                                {item.games.map((game, index) =>
                                    <Link to={`/table/${game.game}/${game.uid}`} className={`game ${item.type}`} key={`${index}`}>

                                        <div className="game-info">
                                            {item.type === "electron"
                                                ? <img src="/images/spades-white.png" alt="Spades" />
                                                : <img src="/images/spades.png" alt="Spades" />
                                            }

                                            <div className="game-text">
                                                <h2>{game.name}</h2>
                                                <span>{game.slug}</span>
                                            </div>
                                        </div>

                                        <div className={`game-available ${parseInt(game.visible) === 0 ? 'game-available-offline' : ''}`}>{parseInt(game.visible) === 1 ? "Включен" : "Отключен"}</div>

                                        <div className="game-button">
                                            Посмотреть
                                            <img src="/images/right-arrow-white.png" alt="Arrow" />
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </div>
                    )
                })}

            </div>
        )
    }
}

export default Games