import React, { Component } from "react"


/* Component Input */
class Input extends Component {

    constructor() {
        super()

        this.state = {
            focused: false,
            secured: true,
            error: ""
        }
    }


    /* Show Error */
    error = error => this.setState({ error })

    /* On Focus */
    focus = () => {

        const { clear } = this.props

        if (clear !== null && clear !== undefined) {
            clear()
        }

        this.setState({ focused: true, error: "" })
    }

    /* On Blur */
    blur = () => {

        const { clear } = this.props

        if (clear !== null && clear !== undefined) {
            clear()
        }

        this.setState({ focused: false, error: "" })
    }


    render = () => {

        const { value, label, secure, file, disabled, ...props } = this.props
        const { focused, error, secured } = this.state

        if (disabled) {
            return (
                <div className="form-input-disabled">
                    <div className="form-input-disabled-label">{label}</div>
                    {value}
                </div>
            )
        }

        if (file) {
            
            return (
                <div className='form-input form-input-focused'>

                    <div onClick={() => !focused && this._input.focus()} className="form-input-label">{label}</div>

                    <input
                        {...props}
                        onFocus={() => this.focus()}
                        onBlur={() => this.blur()}
                        ref={ref => this._input = ref}
                        type={file ? "file" : (secure && secured) ? "password" : "text"}
                    />

                </div>
            )
        }

        return (
            <div className={`form-input ${(focused || value !== "") ? "form-input-focused" : ""}`}>

                <div onClick={() => !focused && this._input.focus()} className="form-input-label">{label}</div>

                <input
                    {...props}
                    value={value}
                    onFocus={() => this.focus()}
                    onBlur={() => this.blur()}
                    ref={ref => this._input = ref}
                    type={(secure && secured) ? "password" : "text"}
                />

                {secure &&
                    <div className="form-input-secure" onClick={() => this.setState({ secured: !secured })}>
                        {secured
                            ? <img src="/images/eye.png" alt="Eye" />
                            : <img src="/images/eye-off.png" alt="Eye Off" />
                        }
                    </div>
                }

                {error !== "" && <div className="form-input-error">{error}</div>}

            </div>
        )
    }

}

export default Input