import axios from "axios"
import { env } from '../constants'


/* List Currency */
export const list = page => {
    const uri = `${env.startpoint}currency/list?page=${page}`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(data => data.response)
}

/* Get Currency */
export const get = data => {
    const uri = `${env.startpoint}currency/get`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Create Currency */
export const create = data => {
    const uri = `${env.startpoint}currency/create`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Edit Currency */
export const edit = data => {
    const uri = `${env.startpoint}currency/edit`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Remove Currency */
export const remove = data => {
    const uri = `${env.startpoint}currency/remove`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}