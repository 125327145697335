import React, { Component } from "react"

/* Components */
import { InnerLayout, Tag, Pagination, Avatar } from '../../components'

/* REST API */
import { list, toggle } from '../../api/Player'

/* Helpers */
import { withRouter, utils } from '../../helpers'

/* Sweetalert */
import Swal from "sweetalert2"

/* Modal */
import Modal from 'react-modal'

/* Widgets */
import { Information, Sessions } from '../../widgets/Player'


/* Fields */
const INFORMATION = "information"
const SESSIONS = "sessions"
const TABS = [
    { key: INFORMATION, text: "Общая информация", image: '/images/player/information.png' },
    { key: SESSIONS, text: "Сессии", image: '/images/player/clock.png' },
]


/* Page Players */
class Players extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            status: "loading",
            count: 0,
            search: "",
            statistics: false,
            tab: INFORMATION,
            player: null
        }

        this._timer = null
        this._scroll = null
    }

    componentDidMount = () => {
        this.load()
    }

    componentDidUpdate = (prevProps) => {

        const { params } = this.props
        const { page } = params

        if (prevProps.params.page !== page) {
            this.load(true)
        }

    }

    componentWillUnmount = () => {
        if (this._timer) {
            clearTimeout(this._timer)
            this._timer = null
        }
    }


    /* Get Page */
    getPage = () => {

        const { params } = this.props
        const { page } = params

        if (Number.isInteger(parseInt(page))) {
            return parseInt(page)
        }

        return 1
    }


    /* Load action */
    load = (reload = false, search = "") => {

        /* Get Page */
        const page = this.getPage()

        /* Reload Page */
        if (reload) {
            this.setState({ status: 'loading' })
        }

        /* Send to REST API */
        list(page, search).then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data.list, count: response.data.count })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [], count: 0 })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [], count: 0 })
            }
            else {
                this.setState({ status: "error", data: [], count: 0 })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [], count: 0 })
        })

    }


    /* Toggle */
    toggle = (item, value) => {

        const { data } = this.state

        Swal.fire({
            title: 'Вы действительно хотите поменять?',
            text: "Эта влияет на статистику",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#03a9f4',
            reverseButtons: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет'
        }).then(result => {
            if (result.isConfirmed) {
                toggle({ id: item.player, value }).then(response => {
                    if (response.status === 200) {

                        const index = data.findIndex(e => e.player === item.player)

                        if (index > -1) {
                            let newData = data
                            newData[index].isTester = value
                            this.setState({ data: newData })
                        }

                        Swal.fire(utils.notification("Статус успешно изменен", "success"))

                    }
                    else if (response.status === 401 || response.status === 403) {
                        Swal.fire(utils.notification("Нет доступа!", "error"))
                    }
                    else if (response.status === 498) {
                        Swal.fire(utils.notification("Неверный ключ!", "error"))
                    }
                    else {
                        Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
                    }
                }).catch(() => {
                    Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
                })
            }
        })

    }


    /* Draw Time */
    _time = time => {

        const datetime = utils.getTime(time)

        return (
            <div className="page-table-datetime">
                <div className="page-table-time">{datetime.time}</div>
                <div className="page-table-date">{datetime.date}</div>
            </div>
        )
    }


    /* Search */
    search = text => {

        this.setState({ search: text, status: 'loading' })

        if (this._timer) {
            clearTimeout(this._timer)
            this._timer = null
        }

        this._timer = setTimeout(() => {
            this.load(false, text)
            clearTimeout(this._timer)
            this._timer = null
        }, 500)

    }


    /* Draw Content */
    _content = () => {

        const { status, data, count } = this.state
        const { navigate } = this.props
        const page = this.getPage()

        if (status !== "ok") {
            return (
                <div className="page-table">
                    <InnerLayout status={status} reload={() => this.load(true, "")} />
                </div>
            )
        }

        return (
            <div className="page-table">

                <div className="player-row-header">
                    <div className="player-number-head">#</div>
                    <div className="player-user-head">Игрок</div>
                    <div className="player-game-head">Последняя игра</div>
                    <div className="player-currency-head">Валюта</div>
                    <div className="player-date-head">Дата и время</div>
                    <div className="player-more-head"></div>
                </div>

                {data.map((item, index) =>
                    <div onClick={() => this.setState({ statistics: true, player: item.player })} className="player-row" key={`${index}`}>

                        {/* Number */}
                        <div className="player-number">{(page - 1) * 10 + index + 1}</div>

                        {/* Player */}
                        <div className="player-user">
                            <Avatar uri={utils.playerAva(parseInt(item.isTester))} size={40} />
                            <div className="player-user-text">
                                <div className="player-user-name">{item.firstName}</div>
                                <div className="player-user-code">{item.player} {parseInt(item.isTester) === 1 && <span>(Тестировщик)</span>}</div>
                            </div>
                        </div>

                        {/* Game */}
                        <div className="player-game">{item.game}</div>

                        {/* Currency */}
                        <div className="player-currency">
                            <Tag color="green">{item.currency}</Tag>
                        </div>

                        {/* Date and Time */}
                        <div className="player-date">{this._time(item.lastLogin)}</div>

                        {/* More Dropdown */}
                        <div className="player-more">

                            <div className="player-more-icon">
                                <img src="/images/dots-more.png" alt="More Dot" />
                            </div>

                            <div className="player-more-menu">

                                <div onClick={() => this.setState({ statistics: true, player: item.player })} className="player-more-menu-item">
                                    <img src="/images/player/analysis.png" alt="Remove" />
                                    <span>Посмотреть статистику</span>
                                </div>

                                <div className="player-more-divider" />

                                <div onClick={() => this.toggle(item, parseInt(item.isTester) === 1 ? 0 : 1)} className="player-more-menu-item">
                                    <img src="/images/player/tester.png" alt="Remove" />
                                    <span>{parseInt(item.isTester) === 0 ? "Отметить как тестировщика" : "Убрать статус тестировщика"}</span>
                                </div>

                            </div>

                        </div>
                    </div>
                )}

                {/* Pagination */}
                <Pagination size={10} page={page} total={count} onPageChange={page => navigate(`/player/${page}`)} />

            </div>
        )
    }


    /* Draw Search */
    _search = () => {

        const { search, status } = this.state

        if (status === "ok" || status === 'loading') {
            return (
                <div className="player-search">
                    <img src="/images/player/search.png" alt="Search" />
                    <input value={search} onChange={event => this.search(event.target.value)} placeholder="Поиск по имени и коду" />
                </div>
            )
        }

    }


    /* Scroll To Top */
    scrollToTop = () => {
        if (this._scroll) {
            this._scroll.scroll({ top: 0, behavior: 'smooth' })
        }
    }

    /* Statistics Modal */
    _statistics = () => {

        const { statistics, tab, player } = this.state

        return (
            <Modal
                isOpen={statistics}
                onRequestClose={() => this.setState({ statistics: false, player: null })}
                className={`statistics--modal`}
                overlayClassName="statistics--overlay"
                closeTimeoutMS={200}
            >

                <div className="statistics--modal-header">
                    <h2>Статистика игрока</h2>
                    <div onClick={() => this.setState({ statistics: false, player: null })} className="emp--modal-close">
                        <img src="/images/close.png" alt="Close" />
                    </div>
                </div>

                <div className="statistics--content" ref={ref => this._scroll = ref}>

                    {/* TABS */}
                    <div className="statistics--tabs">
                        {TABS.map((item, index) =>
                            <div onClick={() => this.setState({ tab: item.key })} className={`statistics--tab ${tab === item.key ? 'active' : 'closed'}`} key={`${index}`}>
                                <img src={`${item.image}`} alt={item.text} />
                                <div className="statistics--tab-text">{item.text}</div>
                            </div>
                        )}
                    </div>

                    {/* Content */}
                    {tab === INFORMATION && <Information player={player} scrollToTop={this.scrollToTop} />}
                    {tab === SESSIONS && <Sessions player={player} scrollToTop={this.scrollToTop} />}

                </div>

            </Modal>
        )
    }


    render = () => (
        <div className="page">

            <div className="player-page-header">
                <h1>Игроки</h1>
                {this._search()}
            </div>

            {this._content()}

            {this._statistics()}

        </div>
    )


}

export default withRouter(Players)