import axios from "axios"
import { env } from '../constants'


/* List Role */
export const list = page => {
    const uri = `${env.startpoint}role/list?page=${page}`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(data => data.response)
}

/* List Permissions */
export const permissions = () => {
    const uri = `${env.startpoint}role/permissions`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(data => data.response)
}

/* Get Role */
export const get = data => {
    const uri = `${env.startpoint}role/get`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Create Role */
export const create = data => {
    const uri = `${env.startpoint}role/create`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Edit Role */
export const edit = data => {
    const uri = `${env.startpoint}role/edit`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Remove Role */
export const remove = data => {
    const uri = `${env.startpoint}role/remove`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}