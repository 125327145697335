import React, { Component } from "react"


/* Fields */
const DOTS = '...'

/* Helpers */
const range = (start, end) => {
    let length = end - start + 1
    return Array.from({ length }, (_, idx) => idx + start)
}



/*
    Component Pagination
*/
class Pagination extends Component {


    generate = () => {

        const { total, size, page } = this.props
        const sibling = 1

        const totalPageCount = Math.ceil(total / size)
        const totalPageNumbers = sibling + 5

        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount)
        }

        const leftSiblingIndex = Math.max(page - sibling, 1)
        const rightSiblingIndex = Math.min(page + sibling, totalPageCount)

        const shouldShowLeftDots = leftSiblingIndex > 2
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

        const firstPageIndex = 1
        const lastPageIndex = totalPageCount


        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 2 + 2 * sibling
            let leftRange = range(1, leftItemCount)

            return [...leftRange, DOTS, totalPageCount]
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 2 + 2 * sibling
            let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount)

            return [firstPageIndex, DOTS, ...rightRange]
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex)
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
        }


        return []
    }


    prev = () => {
        const { page, onPageChange } = this.props
        onPageChange(page - 1)
    }

    next = () => {
        const { page, onPageChange } = this.props
        onPageChange(page + 1)
    }


    /* Draw left button */
    _left = () => {

        const { page } = this.props

        if (parseInt(page) !== 1) {
            return (
                <div onClick={() => this.prev()} className="pagination-left-arrow pagination-item">
                    <img src="/images/left-arrow.png" alt="Right" />
                </div>
            )
        }
        else {
            return (
                <div className="pagination-left-arrow pagination-item pagination-disabled">
                    <img src="/images/left-arrow.png" alt="Right" />
                </div>
            )
        }
    }

    /* Draw right button */
    _right = last => {

        const { page } = this.props

        if (parseInt(page) !== parseInt(last)) {
            return (
                <div onClick={() => this.next()} className="pagination-right-arrow pagination-item">
                    <img src="/images/right-arrow.png" alt="Right" />
                </div>
            )
        }
        else {
            return (
                <div className="pagination-right-arrow pagination-item pagination-disabled">
                    <img src="/images/right-arrow.png" alt="Right" />
                </div>
            )
        }
    }


    render = () => {

        const { onPageChange, total, size, page } = this.props
        const pages = this.generate()

        if (parseInt(total) > 0 && parseInt(total) > parseInt(size)) {

            const last = pages[pages.length - 1]

            return (
                <div className="pagination-box">
                    <div className="pagination">

                        {this._left()}

                        {pages.map((number, index) => {
                            
                            if (number === DOTS) {
                                return <div key={`${index}`} className="pagination-dots">{DOTS}</div>
                            }

                            return <div onClick={() => onPageChange(number)} className={parseInt(page) === parseInt(number) ? "pagination-item pagination-active" : "pagination-item"} key={`${index}`}>{number}</div>
                        })}

                        {this._right(last)}

                    </div>
                    <span>Показано {size * page - (size - 1)} - {size * page} из {total}</span>
                </div>
            )
        }

        return <div />
    }

}

export default Pagination