import React, { Component } from "react"

/* Components */
import { InnerLayout, Pagination, Avatar } from '../../components'

/* REST API */
import { list } from '../../api/Message'

/* Helpers */
import { withRouter, utils } from '../../helpers'


/* Page Messages */
class Messages extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            status: "loading",
            count: 0
        }
    }

    componentDidMount = () => {
        this.load()
    }

    componentDidUpdate = (prevProps) => {
        const { params } = this.props
        const { page } = params

        if (prevProps.params.page !== page) {
            this.load(true)
        }
    }

    /* Get Page */
    getPage = () => {

        const { params } = this.props
        const { page } = params

        if (Number.isInteger(parseInt(page))) {
            return parseInt(page)
        }

        return 1
    }

    /* Load action */
    load = (reload = false) => {

        const page = this.getPage()

        if (reload) {
            this.setState({ status: 'loading' })
        }

        list(page).then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data.list, count: response.data.count })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [], count: 0 })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [], count: 0 })
            }
            else {
                this.setState({ status: "error", data: [], count: 0 })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [], count: 0 })
        })

    }


    /* Draw Time */
    _time = time => {

        const datetime = utils.getTime(time)

        return (
            <div className="page-table-datetime">
                <div className="page-table-time">{datetime.time}</div>
                <div className="page-table-date">{datetime.date}</div>
            </div>
        )
    }

    render = () => {

        const { status, data, count } = this.state
        const { navigate } = this.props
        const page = this.getPage()

        if (status !== "ok") {
            return (
                <div className="page">

                    <div className="page-header">
                        <h1>Сообщение</h1>
                    </div>

                    <div className="page-table">
                        <InnerLayout status={status} reload={() => this.load(true)} />
                    </div>

                </div>
            )
        }

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Сообщение</h1>
                </div>

                <div className="page-table">

                    <div className="message-row-header">
                        <div className="message-number-head">#</div>
                        <div className="message-user-head">Игрок</div>
                        <div className="message-game-head">Игра</div>
                        <div className="message-text-head">Сообщение</div>
                        <div className="message-date-head">Дата и время</div>
                    </div>

                    {data.map((item, index) =>
                        <div className="message-row" key={`${index}`}>

                            {/* Number */}
                            <div className="message-number">{(page - 1) * 10 + index + 1}</div>

                            {/* Player */}
                            <div className="message-user">
                                <Avatar uri={utils.playerAva(parseInt(item.isTester))} size={40} />
                                <div className="message-user-text">
                                    <div className="message-user-name">{item.firstName}</div>
                                    <div className="message-user-code">{item.player}</div>
                                </div>
                            </div>

                            {/* Game */}
                            <div className="message-game">{item.game}</div>

                            {/* Currency */}
                            <div className="message-text">{item.message}</div>

                            {/* Date and Time */}
                            <div className="message-date">{this._time(item.createdAt)}</div>
                        </div>
                    )}

                    <Pagination size={10} page={page} total={count} onPageChange={page => navigate(`/message/${page}`)} />

                </div>


            </div>
        )
    }

}

export default withRouter(Messages)