import React, { Component } from "react"

/* Components */
import { Layout, Pagination } from '../../components'

/* REST API */
import { list, remove } from '../../api/Admin'

/* Router */
import { Link } from "react-router-dom"

/* Helpers */
import { withRouter, utils } from '../../helpers'

/* Sweet alert */
import Swal from "sweetalert2"

/* JWT */
import { decodeToken } from "react-jwt"

/* Fields */
const PERCENTAGE = 90 / 3



/* Page Admins */
class Admins extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            status: "loading",
            count: 0
        }
    }

    componentDidMount = () => {
        this.load()
    }

    componentDidUpdate = (prevProps) => {

        const { params } = this.props
        const { page } = params

        if (prevProps.params.page !== page) {
            this.load()
        }

    }

    /* Get Page */
    getPage = () => {

        const { params } = this.props
        const { page } = params

        if (Number.isInteger(parseInt(page))) {
            return parseInt(page)
        }

        return 1
    }

    /* Load action */
    load = (reload = false) => {

        const page = this.getPage()

        if (reload) {
            this.setState({ status: 'loading' })
        }

        list(page).then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data.list, count: response.data.count })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [], count: 0 })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [], count: 0 })
            }
            else {
                this.setState({ status: "error", data: [], count: 0 })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [], count: 0 })
        })

    }

    /* Remove action */
    remove = id => {

        const { data } = this.state

        Swal.fire({
            title: 'Вы действительно хотите удалить?',
            text: "Невозможно восстановить после удаления",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f44336',
            reverseButtons: true,
            confirmButtonText: 'Удалить!',
            cancelButtonText: 'Отмена'
        }).then(result => {
            if (result.isConfirmed) {
                remove({ id }).then(response => {
                    if (response.status === 200) {
                        const index = data.findIndex(e => parseInt(e.id) === parseInt(id))

                        if (index > -1) {
                            let newData = data
                            newData.splice(index, 1)
                            this.setState({ data: newData })
                        }

                        Swal.fire(utils.notification("Пользователь успешно удален", "success"))
                    }
                    else if (response.status === 401 || response.status === 403) {
                        Swal.fire(utils.notification("Нет доступа!", "error"))
                    }
                    else if (response.status === 498) {
                        Swal.fire(utils.notification("Неверный ключ!", "error"))
                    }
                    else {
                        Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
                    }
                }).catch(() => {
                    Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
                })
            }
        })
    }

    render = () => {

        const { status, data, count } = this.state
        const { navigate } = this.props
        const page = this.getPage()
        const token = localStorage.getItem("token")
        const decoded = decodeToken(token)

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Пользователи</h1>
                    <Link to="/user/create" className="button">
                        <img src="/images/plus.png" alt="Plus" />
                        <span>Добавить</span>
                    </Link>
                </div>

                <div className="page-table">

                    <div className="page-table-header">
                        <div className="page-table-column number">#</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>Имя</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>Email</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>Роль</div>
                        <div className="page-table-column more"></div>
                    </div>

                    {data.map((item, index) =>
                        <div className="page-table-body currencies" key={`${index}`}>

                            <div className="page-table-column number">{page * (index + 1)}</div>
                            <div className="page-table-column bold" style={{ width: `${PERCENTAGE}%` }}>{item.name}</div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>{item.email}</div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>{item.role}</div>

                            {parseInt(item.isAdmin) === 1 || item.token === decoded.token
                                ? <div className="page-table-column more" />
                                : <div className="page-table-column more">

                                    <div className="more-dropdown-menu-icon">
                                        <img src="/images/dots-more.png" alt="More Dot" />
                                    </div>

                                    <div className="more-dropdown-menu">

                                        <Link to={`/user/activity/${item.id}`} className="more-dropdown-menu-item">
                                            <img src="/images/activity.png" alt="Activity" />
                                            <span>Активность</span>
                                        </Link>

                                        <Link to={`/user/history/${item.id}`} className="more-dropdown-menu-item">
                                            <img src="/images/clock.png" alt="Clock" />
                                            <span>История входа</span>
                                        </Link>

                                        <Link to={`/user/accept/${item.id}`} className="more-dropdown-menu-item">
                                            <img src="/images/accept.png" alt="Accept" />
                                            <span>Изменить доступы</span>
                                        </Link>

                                        <Link to={`/user/edit/${item.id}`} className="more-dropdown-menu-item">
                                            <img src="/images/editing.png" alt="Edit" />
                                            <span>Редактировать</span>
                                        </Link>

                                        <Link to={`/user/password/${item.id}`} className="more-dropdown-menu-item">
                                            <img src="/images/password.png" alt="Password" />
                                            <span>Изменить пароль</span>
                                        </Link>

                                        <div onClick={() => this.remove(item.id)} className="more-dropdown-menu-item red">
                                            <img src="/images/remove.png" alt="Remove" />
                                            <span>Удалить</span>
                                        </div>

                                    </div>

                                </div>
                            }
                        </div>
                    )}

                    <Pagination size={10} page={page} total={count} onPageChange={page => navigate(`/user/${page}`)} />

                </div>

            </div>
        )
    }

}

export default withRouter(Admins)