import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'

/* CSS */
import './design/css/app.css'
import './design/css/dashboard.css'
import './design/css/auth.css'
import './design/css/input.css'
import './design/css/menu.css'
import './design/css/header.css'
import './design/css/page404.css'
import './design/css/page.css'
import './design/css/layout.css'
import './design/css/pagination.css'
import './design/css/permission.css'
import './design/css/game.css'
import './design/css/reload.css'
import 'react-datepicker/dist/react-datepicker.css'
import './design/css/table.css'
import './design/css/rmp.css'
import './design/css/omp.css'
import './design/css/qmp.css'
import './design/css/ump.css'
import './design/css/hmp.css'
import './design/css/rvp.css'
import './design/css/emp.css'
import './design/css/qvp.css'
import './design/css/evp.css'
import './design/css/rep.css'
import './design/css/r2ep.css'
import './design/css/sep.css'
import './design/css/oep.css'
import './design/css/qep.css'
import './design/css/eep.css'
import './design/css/nep.css'
import './design/css/player.css'
import './design/css/process.css'
import './design/css/transaction.css'
import './design/css/cards.css'
import './design/css/refund.css'
import './design/css/video.css'
import './design/css/game-player.css'
import './design/css/notes.css'
import './design/css/monitor.css'
import './design/css/messages.css'
import './design/css/statistics.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

reportWebVitals()
