import axios from "axios"
import { env } from '../constants'


/* List Admin */
export const list = page => {
    const uri = `${env.startpoint}admin/list?page=${page}`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(data => data.response)
}

/* List Role */
export const roles = () => {
    const uri = `${env.startpoint}admin/roles`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(data => data.response)
}

/* Create Admin */
export const create = data => {
    const uri = `${env.startpoint}admin/create`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Get Admin */
export const get = data => {
    const uri = `${env.startpoint}admin/get`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Edit Admin */
export const edit = data => {
    const uri = `${env.startpoint}admin/edit`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Remove Admin */
export const remove = data => {
    const uri = `${env.startpoint}admin/remove`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Change Admin Password */
export const password = data => {
    const uri = `${env.startpoint}admin/password`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* History Admin */
export const history = data => {
    const uri = `${env.startpoint}admin/history`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Activity Admin */
export const activity = data => {
    const uri = `${env.startpoint}admin/activity`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}


/* Games Admin */
export const games = data => {
    const uri = `${env.startpoint}admin/games`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}


/* Change Game Accept Admin */
export const changeAccept = data => {
    const uri = `${env.startpoint}admin/change-accept`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}
