import React, { Component } from "react"

/* REST API */
import { profile, save, upload, remove } from '../../api/Profile'

/* Components */
import { Input, Layout } from "../../components"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Helpers */
import { utils } from '../../helpers'

/* Constants */
import { env } from '../../constants'

/* Swal */
import Swal from 'sweetalert2'



/* Page Profile */
class Profile extends Component {

    constructor() {
        super()

        this.state = {
            status: 'loading',
            image: null,
            name: "",
            email: "",
            waiting: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load from REST API */
    load = (reload = false) => {

        if (reload) {
            this.setState({ status: 'loading' })
        }

        profile().then(response => {
            if (response.status === 200) {

                const data = response.data

                this.setState({
                    status: "ok",
                    name: utils.checkValue(data.name),
                    email: utils.checkValue(data.email),
                    image: utils.checkValue(data.image, null),
                })

            }
            else if (response.status === 401) {
                this.setState({ status: "permission" })
            }
            else if (response.status === 498) {
                this.setState({ status: "key" })
            }
            else {
                this.setState({ status: "error" })
            }
        }).catch(() => {
            this.setState({ status: "network" })
        })

    }


    /* Save action */
    save = () => {

        const { name } = this.state

        if (name === "") {
            this._name.error("Введите имя")
            return
        }

        save({ name }).then(response => {
            if (response.status === 200) {
                
                try {
                    const text = localStorage.getItem("user")
                    let user = JSON.parse(text)
                    user.name = name
                    localStorage.setItem("user", JSON.stringify(user))
                }
                catch {
                    console.log("Something went wrong!")
                }

                Swal.fire(utils.notification("Данные успешно сохранены", "success"))
            }
            else if (response.status === 498) {
                Swal.fire(utils.notification("Неверный ключ!", "error"))
            }
            else {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
        })

    }

    /* Upload action */
    uploadFile = event => {

        const file = event.target.files[0]

        if (file) {

            const data = new FormData()
            data.append('image', file)

            upload(data).then(response => {
                if (response.status === 200) {
                    this.setState({ image: response.data.image })
                    Swal.fire(utils.notification("Изображение успешно сохранено", "success"))
                }
                else if (response.status === 498) {
                    Swal.fire(utils.notification("Неверный ключ!", "error"))
                }
                else {
                    Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
                }
            }).catch(() => {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            })

        }
    }

    /* Upload action */
    removeFile = () => {
        remove().then(response => {
            if (response.status === 200) {
                this.setState({ image: null })
                Swal.fire(utils.notification("Изображение успешно удалено", "success"))
            }
            else if (response.status === 498) {
                Swal.fire(utils.notification("Неверный ключ!", "error"))
            }
            else {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
        })
    }

    /* Draw button */
    _button = () => {

        const { waiting } = this.state

        if (waiting) {
            return <div className="button"><LoadingOutlined /></div>
        }

        return <div onClick={() => this.save()} className="button">Сохранить</div>
    }


    /* Draw image */
    _image = () => {

        const { image } = this.state

        if (image) {
            return (
                <div className="form-image-box">
                    <span>Изображение</span>

                    <label className="form-image">
                        <input onChange={event => this.uploadFile(event)} type="file" name="file" accept="image/png, image/jpeg" />

                        <div className="form-image-edit-button">
                            <img src="/images/upload.png" alt="Upload" />
                        </div>

                        <div className="form-image-picture">
                            <img src={`${env.mediapoint}${image}`} alt="Avatar" />
                        </div>

                    </label>

                    <div onClick={() => this.removeFile()} className="form-image-remove-button">
                        <img src="/images/delete.png" alt="Delete" />
                    </div>

                </div>
            )
        }

        return (
            <div className="form-image-box">
                <span>Изображение</span>
                <label className="form-image">
                    <input onChange={event => this.uploadFile(event)} type="file" name="file" accept="image/png, image/jpeg" />
                    <div className="form-image-button">
                        <img src="/images/upload.png" alt="Upload" />
                    </div>
                </label>
            </div>
        )
    }


    render = () => {

        const { name, email, status } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Профиль</h1>
                </div>

                <div className="page-form">

                    <div className="form">
                        <div className="form-box">

                            {this._image()}

                            <Input ref={ref => this._name = ref} label="Имя" value={name} onChange={event => this.setState({ name: event.target.value })} />
                            <Input label="Email" value={email} disabled />

                        </div>
                    </div>

                    <div className="form-bottom">
                        {this._button()}
                    </div>

                </div>

            </div>
        )
    }
}

export default Profile