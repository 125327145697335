import axios from "axios"
import { env } from '../constants'


/* List Message */
export const list = page => {
    const uri = `${env.startpoint}message/list?page=${page}`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(data => data.response)
}
