import axios from "axios"
import { env } from '../constants'


/* LOGIN ROUTE */
export const login = data => {
    const uri = `${env.startpoint}authorization/login`
    return axios.post(uri, data).then(response => response).catch(data => data.response)
}

/* REFRESH ROUTE */
export const refresh = data => {
    const uri = `${env.startpoint}authorization/refresh`
    return axios.post(uri, data).then(response => response).catch(data => data.response)
}