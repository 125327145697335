import React, { Component } from "react"

/* Components */
import { Layout, Pagination, Tag } from '../../components'

/* REST API */
import { list } from '../../api/Card'

/* Router */
import { Link } from "react-router-dom"

/* Helpers */
import { withRouter } from '../../helpers'

/* Constants */
import { env } from "../../constants"


/* Fields */
const PERCENTAGE = 90 / 4



/* Component Cards */
class Cards extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            status: "loading",
            count: 0
        }
    }

    componentDidMount = () => {
        this.load()
    }

    componentDidUpdate = (prevProps) => {

        const { params } = this.props
        const { page } = params

        if (prevProps.params.page !== page) {
            this.load()
        }

    }

    /* Get Page */
    getPage = () => {

        const { params } = this.props
        const { page } = params

        if (Number.isInteger(parseInt(page))) {
            return parseInt(page)
        }

        return 1
    }

    /* Load action */
    load = (reload = false) => {

        const page = this.getPage()

        if (reload) {
            this.setState({ status: 'loading' })
        }

        list(page).then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data.list, count: response.data.count })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [], count: 0 })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [], count: 0 })
            }
            else {
                this.setState({ status: "error", data: [], count: 0 })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [], count: 0 })
        })

    }

    render = () => {

        const { status, data, count } = this.state
        const { navigate } = this.props
        const page = this.getPage()

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Карты</h1>
                </div>

                <div className="page-table">

                    <div className="page-table-header">
                        <div className="page-table-column number">#</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>Название</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>Изображение</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>Значение</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>Barcode</div>
                        <div className="page-table-column more"></div>
                    </div>

                    {data.map((item, index) =>
                        <div className="page-table-body cards" key={`${index}`}>
                            <div className="page-table-column number">{page * (index + 1)}</div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>{item.name}</div>
                            <div className="page-table-column card" style={{ width: `${PERCENTAGE}%` }}>
                                <img src={`${env.mediapoint}cards/${item.image}`} alt="Card" />
                            </div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}><Tag color="green">{item.value}</Tag></div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}><Tag color="pink">{item.barcode}</Tag></div>
                            <div className="page-table-column more">

                                <div className="more-dropdown-menu-icon">
                                    <img src="/images/dots-more.png" alt="More Dot" />
                                </div>

                                <div className="more-dropdown-menu">

                                    <Link to={`/card/edit/${item.id}`} className="more-dropdown-menu-item">
                                        <img src="/images/editing.png" alt="Edit" />
                                        <span>Изменить BARCODE</span>
                                    </Link>

                                </div>

                            </div>
                        </div>
                    )}

                    <Pagination size={10} page={page} total={count} onPageChange={page => navigate(`/card/${page}`)} />

                </div>


            </div>
        )
    }

}

export default withRouter(Cards)