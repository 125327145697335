import React, { Component } from "react"

/* Components */
import { Layout, Input } from '../../components'

/* REST API */
import { get, edit } from '../../api/Role'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Helpers */
import { utils, withRouter } from '../../helpers'

/* Swal */
import Swal from 'sweetalert2'



/* Page Role Edit */
class RoleEdit extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            status: "loading",
            name: "",
            checked: [],
            waiting: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Permissions */
    load = (reload = false) => {

        const { params } = this.props
        const { id } = params

        if (id) {

            if (reload) {
                this.setState({ status: 'loading' })
            }

            get({ id }).then(response => {
                if (response.status === 200) {

                    const data = response.data

                    let checked = []

                    if (data.list && Array.isArray(data.list) && data.list.length > 0) {
                        data.list.forEach(item => {
                            if (parseInt(item.isChecked) > 0) {
                                checked.push(item)
                            }
                        })
                    }

                    this.setState({ status: "ok", data: data.list, checked, name: utils.checkValue(data.name) })
                }
                else if (response.status === 401 || response.status === 403) {
                    this.setState({ status: "permission", data: [] })
                }
                else if (response.status === 498) {
                    this.setState({ status: "key", data: [] })
                }
                else {
                    this.setState({ status: "error", data: [] })
                }
            }).catch(() => {
                this.setState({ status: "network", data: [] })
            })

        }

    }

    /* Permissions group */
    permissions = () => {

        const { data } = this.state

        let types = []

        data.forEach(item => {

            const index = types.findIndex(e => e.type === item.type)

            if (index === -1) {
                types.push({ type: item.type, list: [] })
            }

        })


        data.forEach(item => {

            const index = types.findIndex(e => e.type === item.type)

            if (index > -1) {
                types[index].list.push({ id: item.id, name: item.name, text: item.text })
            }

        })

        return types
    }

    /* Determine */
    findPermission = item => {

        const { checked } = this.state

        const index = checked.findIndex(e => e.name === item.name)

        return index > -1
    }

    /* Add */
    togglePermission = (item, type, action) => {

        const { checked } = this.state
        let list = checked


        if (type === "item") {

            const index = checked.findIndex(e => e.name === item.name)

            if (index > -1) {
                list.splice(index, 1)
                this.setState({ checked: list })
            }
            else {
                list.push(item)
                this.setState({ checked: list })
            }

        }


        if (type === "list" && Array.isArray(item)) {

            item.forEach(permission => {

                const index = checked.findIndex(e => e.name === permission.name)

                if (index > -1 && action === "clear") {
                    list.splice(index, 1)
                }

                if (index === -1 && action === "add") {
                    list.push(permission)
                }

            })

            this.setState({ checked: list })

        }

    }


    /* Save acton */
    save = () => {

        const { name, checked } = this.state
        const { navigate, params } = this.props
        const { id } = params

        if (!id) {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            return
        }

        if (name === "") {
            this._name.error("Введите название роли")
            return
        }

        edit({ id, name, checked }).then(response => {
            if (response.status === 200) {
                Swal.fire(utils.notification("Роль успешно сохранена", "success")).then(() => {
                    navigate(-1)
                })
            }
            else if (response.status === 401 || response.status === 403) {
                Swal.fire(utils.notification("Нет доступа!", "error"))
            }
            else if (response.status === 498) {
                Swal.fire(utils.notification("Неверный ключ!", "error"))
            }
            else {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
        }).finally(() => {
            this.setState({ waiting: false })
        })

    }


    /* Draw button */
    _button = () => {

        const { waiting } = this.state

        if (waiting) {
            return <div className="button"><LoadingOutlined /></div>
        }

        return <div onClick={() => this.save()} className="button">Сохранить</div>
    }

    render = () => {

        const { status, name } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        const permissions = this.permissions()

        return (
            <div className="page">

                <div className="page-header">
                    <h1>Редактировать роль</h1>
                </div>

                <div className="page-form">

                    <div className="form">
                        <div className="form-box">
                            <Input ref={ref => this._name = ref} label="Название" value={name} onChange={event => this.setState({ name: event.target.value })} />
                        </div>
                    </div>

                    <div className="permissions">
                        {permissions.map((item, index) =>
                            <div key={`${index}`} className="permission">

                                <div className="permission-type">
                                    Доступы к <span>{item.type}</span>
                                    <div className="permission-actions">
                                        <div onClick={() => this.togglePermission(item.list, "list", "add")} className="permission-action">Выбрать все</div>
                                        <div onClick={() => this.togglePermission(item.list, "list", "clear")} className="permission-action">Отменить все</div>
                                    </div>
                                </div>

                                {item.list.map((p, i) =>
                                    <label className="permission-box" key={`${i}`}>
                                        <input type="checkbox" checked={this.findPermission(p)} onChange={() => this.togglePermission(p, "item")} />
                                        <div className="permission-name">
                                            <div className="permission-title">{p.text}</div>
                                            <div className="permission-text">{p.name}</div>
                                        </div>
                                    </label>
                                )}

                            </div>
                        )}
                    </div>

                    <div className="form-bottom form-bottom-full">
                        {this._button()}
                    </div>

                </div>


            </div>
        )
    }

}

export default withRouter(RoleEdit)