import React, { Component } from "react"

/* Components */
import { Layout } from '../../components'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Swal */
import Swal from 'sweetalert2'

/* Helpers */
import { utils } from '../../helpers'

/* REST API */
import { message as send, messages } from '../../api/Table'



/* Widget Message */
class Message extends Component {

    constructor() {
        super()

        this.state = {
            message: "",
            waiting: false,
            messages: [],
            loading: true,
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load action */
    load = () => {

        const { uid } = this.props

        messages({ uid }).then(response => {
            if (response.status === 200) {
                this.setState({ messages: response.data, loading: false })
            }
        })
    }

    /* Send action */
    send = () => {

        const { message } = this.state
        const { uid, socket } = this.props

        /* Check Params */
        if (!uid) {
            Swal.fire(utils.notification("Неизвестная ошибка! Пожалуйста, повторите попытку позже.", "error"))
            return
        }

        if (message === "") {
            Swal.fire(utils.notification("Введите сообщение", "error"))
            return
        }

        this.setState({ waiting: true })

        send({ uid, message }).then(response => {
            if (response.status === 200) {
                const { messages } = this.state
                messages.unshift(response.data)
                this.setState({ messages })
                socket.emit("messageToPlayer", { message, name: 'Служба поддержки' })
                this.setState({ message: "" })
                Swal.fire(utils.notification("Сообщение успешно отправлено", "success", 3000))
            }
            else if (response.status === 401 || response.status === 403) {
                Swal.fire(utils.notification("Нет доступа!", "error"))
            }
            else if (response.status === 498) {
                Swal.fire(utils.notification("Неверный ключ!", "error"))
            }
            else {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
        }).finally(() => {
            this.setState({ waiting: false })
        })

    }


    /* Draw Button */
    _button = () => {

        const { waiting } = this.state

        if (waiting) {
            return <div className="table--message-button"><LoadingOutlined /></div>
        }

        return <div onClick={() => this.send()} className="table--message-button">Отправить</div>
    }


    /* Draw Messages */
    _messages = () => {

        const { messages, loading } = this.state

        if (loading) {
            return (
                <div className="table--modal-message-loading">
                    <LoadingOutlined />
                </div>
            )
        }

        if (messages && Array.isArray(messages) && messages.length > 0) {

            return (
                <div className="table--modal-messages">
                    {messages.map((message, index) => {
                        const datetime = utils.getTime(message.createdAt)
                        return (
                            <div className="table--modal-message" key={`${index}`}>
                                <div className="table--modal-message-name">{message.firstName}</div>
                                <div className="table--modal-message-text">{message.message}</div>
                                <div className="table--modal-message-datetime">{datetime.time}, {datetime.date}</div>
                            </div>
                        )
                    })}
                </div>
            )
        }

    }


    render = () => {

        const { socket } = this.props
        const { message } = this.state

        if (!socket) {
            return <Layout status="loading" />
        }

        return (
            <div className="table--modal-content">

                <textarea className="table--message-textarea" placeholder="Сообщение" value={message} onChange={event => this.setState({ message: event.target.value })}></textarea>

                {this._button()}

                {this._messages()}

            </div>
        )
    }

}

export default Message