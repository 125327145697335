import React, { useState, useEffect } from "react"

/* Router */
import { Navigate } from "react-router-dom"
import { useLocation } from 'react-router-dom'

/* JWT */
import { isExpired } from "react-jwt"

/* REST API */
import { refresh as refreshToken } from '../api/Authorization'

/* Cookies */
import Cookies from "js-cookie"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'



/* Helper Protected Route */
const ProtectedRoute = ({ setToken, children }) => {

    const location = useLocation()

    const [token, saveToken] = useState(localStorage.getItem("token"))
    const [refresh, saveRefresh] = useState(Cookies.get("refresh"))

    let tokenStatus = "undetermined"

    if (!refresh || isExpired(refresh)) {
        tokenStatus = "expired"
    }
    else {
        if (!token || isExpired(token)) {
            tokenStatus = "undetermined"
        }
        else {
            tokenStatus = "ok"
        }
    }

    const [status, setStatus] = useState(tokenStatus)
    const [loading, setLoading] = useState(false)


    useEffect(() => {

        let tokenStatus = "undetermined"

        if (!refresh || isExpired(refresh)) {
            tokenStatus = "expired"
        }
        else {
            if (!token || isExpired(token)) {
                tokenStatus = "undetermined"
            }
            else {
                tokenStatus = "ok"
            }
        }
        
        if (tokenStatus === "undetermined") {

            setLoading(true)

            refreshToken({ refresh }).then(response => {
                if (response.status === 200) {

                    localStorage.setItem("token", response.data.accessToken)
                    Cookies.set("refresh", response.data.refreshToken, { expires: 1 })

                    setStatus("ok")
                    setToken(response.data.accessToken)
                    saveToken(response.data.accessToken)
                    saveRefresh(response.data.refreshToken)
                }
                else {
                    setStatus("expired")
                }
            }).catch(() => {
                setStatus("expired")
            }).finally(() => {
                setLoading(false)
            })

        }

    }, [location, refresh, token, setToken])


    if (loading) {
        return (
            <div className="loading">
                <LoadingOutlined />
            </div>
        )
    }

    if (status === "expired") {
        return <Navigate to="/" />
    }

    return children

}


export default ProtectedRoute