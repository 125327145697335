import React, { Component } from "react"

/* Components */
import { Tag } from '../../components'

/* Widgets */
import Layout from './Layout'

/* REST API */
import { dealers, toggleDealer } from '../../api/Table'

/* Switch */
import Switch from "react-switch"

/* Swal */
import Swal from 'sweetalert2'

/* Helpers */
import { utils } from '../../helpers'



/* Widget Dealer */
class Dealer extends Component {

    constructor() {
        super()

        this.state = {
            status: 'loading',
            data: [],
        }
    }

    componentDidMount = () => {
        this.load()
    }


    /* Load Dealers Data */
    load = (reload = false) => {

        /* Fields */
        const { uid } = this.props

        /* Check Params */
        if (!uid) {
            this.setState({ status: 'error', data: [] })
            return
        }

        /* Refresh the page if necessary */
        if (reload) {
            this.setState({ status: 'loading' })
        }

        /* START REST API */
        dealers({ uid }).then(response => {
            if (response.status) {
                this.setState({ status: "ok", data: response.data })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [] })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [] })
            }
            else {
                this.setState({ status: "error", data: [] })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [] })
        })

    }



    /* Change Value */
    change = (dealer, status) => {

        /* Fields */
        const { uid } = this.props
        const { data } = this.state

        /* Check Params */
        if (!uid) {
            Swal.fire(utils.notification("Неизвестная ошибка! Пожалуйста, повторите попытку позже.", "error"))
            return
        }

        toggleDealer({ uid, dealerID: dealer.id, status }).then(response => {
            if (response.status === 200) {
                let dealers = data

                const index = dealers.findIndex(e => parseInt(e.id) === parseInt(dealer.id))

                if (index > -1) {
                    dealers[index].enabled = status ? 1 : 0
                    this.setState({ data: dealers })
                }

                Swal.fire(utils.notification(status ? "Дилер добавлен" : "Дилер убран", "success"))
            }
            else if (response.status === 401 || response.status === 403) {
                Swal.fire(utils.notification("Нет доступа!", "error"))
            }
            else if (response.status === 498) {
                Swal.fire(utils.notification("Неверный ключ!", "error"))
            }
            else {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
        })

    }


    render = () => {

        const { status, data } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="table--modal-content">

                {data.map((item, index) =>
                    <div className="table--dealer-item" key={`${index}`}>

                        <div className="table--dealer-item-text">
                            <h2>{item.name}</h2>
                            <p>{item.uid}</p>
                            <Tag color="pink">{item.pincode}</Tag>
                        </div>

                        <Switch uncheckedIcon={false} checkedIcon={false} onColor="#8bc34a" onChange={value => this.change(item, value)} checked={parseInt(item.enabled) === 1} />

                    </div>
                )}
                
            </div>
        )
    }

}

export default Dealer