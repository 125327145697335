import React, { Component } from "react"

/* React Player */
import ReactPlayer from 'react-player/file'


/* Fields */
const playerConfig = {
    file: {
        hlsOptions: {
            enableWorker: true,
            lowLatencyMode: true,
            backBufferLength: 0,
            startLevel: 0,
            liveSyncDuration: 6,
            liveMaxLatencyDuration: 7,
            maxLiveSyncPlaybackRate: 1.2,
        }
    }
}

/* Component Video */
class Video extends Component {

    constructor() {
        super()

        this.state = {
            visible: false
        }
    }

    render = () => {

        const { url } = this.props
        const { visible } = this.state

        return (
            <div className={`game-video ${visible ? 'opened' : 'closed'}`}>

                <div onClick={() => this.setState({ visible: !visible })} className="game-video-live">
                    <div className="game-video-live-text">Live</div>
                </div>

                <div className="game-video-container">
                    <ReactPlayer
                        playing={true}
                        url={url}
                        config={playerConfig}
                        muted={true}
                        playsinline={true}
                        width='100%'
                        height='100%'
                        controls={true}
                    />
                </div>

            </div>
        )
    }

}

export default Video